@import '../../assets/sass/exrond.scss';

$tab-active: #141e2f;
$tab-normal: #26334a;
.exrond-liquidity-card {
  background-color: transparent;
  border: none;
  box-shadow: 0 1rem 2rem 0 rgb(0 0 0 / 30%);
  border-radius: 40px;
  .nav {
    border: none;
    .nav-item {
      border: none;
      .nav-link {
        border: none;
        background-color: $tab-normal;
        @extend .exrond-text;
        @extend .text-white;
        @extend .font-size-1;
        @extend .font-weight-600;
        
        &.active {
          background-color: $tab-active
        }
      }
    }
  }
  .tab-content {
    background-color: $tab-active;
    border-bottom-right-radius: 40px;
    border-bottom-left-radius: 40px;
    padding: 0px 25px 20px 25px;
  }
  .left {
    border-top-left-radius: 40px;
  }
  .right {
    border-top-right-radius: 40px;
  }
}