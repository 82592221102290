@import 'assets/sass/css-breakpoint-mixins.scss';
@import 'assets/sass/variables.module.scss';
@import 'assets/sass/exrond.scss';

.swap-settings {
  width: calculateEm(350);
  margin: 0 auto;
  border-radius: calculateEm(16);
  padding: calculateEm(16);
  color: $color-white;
  background-color: $color-primary;
  margin-bottom: calculateEm(5);
  display: flex;
  flex-direction: column-reverse;
  position: relative;

  @include lg {
    width: 100%;
    flex-direction: column;
    padding: calculateEm(30);
  }

  &__content {
    padding: 0;
    display: flex;
    flex-direction: column-reverse;

    @include lg {
      padding: 0 calculateEm(40);
      flex-direction: column;
    }
  }

  &__toggle {
    background: none;
    border: none;
    font-size: calculateEm(16);
    margin-top: -4px;
    color: $color-gray;
    position: absolute;
    top: calculateEm(16);
    bottom: unset;
    right: calculateEm(16);

    &:hover {
      color: $color-white;
    }

    @include lg {
      top: unset;
      bottom: calculateEm(6);
      right: calculateEm(16);
    }
  }

  &__text {
    @extend .exrond-text;
    color: $color-white;
    font-size: calculateEm(10);
    font-weight: 500;

    @include lg {
      font-size: calculateEm(12);
    }
  }

  &__info,
  &__impact {
    display: flex;
    flex-direction: column-reverse;

    @include lg {
      flex-direction: row;

      & > *:first-child {
        width: 45%;
      }

      & > *:nth-child(2) {
        width: 55%;
      }
    }
  }

  &__swap-route,
  &__initial-liquidity,
  &__total-liquidity,
  &__impact,
  &__exchange-rate {
    display: flex;
    align-items: flex-start;
    margin: calculateRem(6) 0;

    ul {
      list-style-type: none;
      margin: 0;
      padding-left: 16px;
    }

    div {
      display: flex;
      flex-direction: column;
    }

    table {
      font-size: 0.85rem;

      td {
        padding: 6px 0;
      }
    }

    @include lg {
      margin: unset;

      table {
        font-size: 1rem;
        margin-top: -2px;
        margin-left: 16px;
      }
    }
  }

  &__exchange-rate {
    display: flex;

    div {
      display: flex;
      flex-direction: row;
    }
  }

  &__liquidity {
    &-total {
      min-width: 29%;
    }

    &-first-token {
      min-width: 32%;
    }

    &-second-token {
      min-width: 32%;
    }

    &--spacing {
      margin: 0 calculateRem(4);

      @include lg {
        margin: 0 calculateRem(8);
      }
    }
  }

  &-icon {
    width: calculateRem(20);
    height: calculateRem(20);
    border-radius: 50%;
    background-color: $color-primary;
  }

  &__slippage {
    display: flex;
    justify-content: center;
    margin-top: calculateRem(16);
    margin-bottom: calculateRem(0);

    @include lg {
      margin-top: calculateRem(0);
      margin-bottom: calculateRem(16);
    }

    &-btn {
      width: calculateRem(51);
      height: calculateRem(42);
      @extend .exrond-text;
      font-size: calculateEm(10);
      font-weight: 500;
      border: 1px solid $color-gray;
      color: $color-gray;
      border-radius: calculateRem(12);
      background-color: transparent;
      margin-right: calculateRem(5);

      @include lg {
        width: calculateRem(66);
        height: calculateRem(51);
        margin-right: calculateRem(10);
        font-size: calculateEm(12);
      }

      &--active {
        border: 1px solid $color-success;
        color: $color-success;
      }
    }

    &-input {
      height: calculateRem(42);
      position: relative;
      background-color: transparent;
      border: 1px solid $color-gray;
      color: $color-success;
      border-radius: calculateRem(12);
      padding: 0 calculateRem(10) 0 calculateRem(10);
      width: 100%;
      font-size: calculateEm(10);

      &--active {
        border: 1px solid $color-success;
        color: $color-success;
      }

      &--error {
        border: 1px solid $color-error;
        color: $color-error;
      }

      &:focus-visible {
        outline: none;
      }

      &::placeholder {
        color: $color-gray;
      }

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        margin: 0;
        -webkit-appearance: none;
      }

      /* Firefox */
      &[type='number'] {
        -moz-appearance: textfield;
      }

      @include lg {
        font-size: calculateEm(12);
        height: calculateRem(51);
      }
    }

    &-info {
      position: absolute;
      right: calculateRem(-20);
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__initial-liquidity,
  &__total-liquidity {
    flex-direction: column;

    div {
      flex-direction: row;
    }

    @include lg {
      flex-direction: row;

      div {
        flex-direction: column;
      }
    }
  }
}
