@import '/src/assets/sass/variables.module.scss';
@import '/src/assets/sass/css-breakpoint-mixins.scss';
@import '/src/assets/sass/exrond.scss';

.swap-option {
  height: calculateEm(51);

  &__mark {
    border-top-right-radius: calculateRem(3);
    border-bottom-right-radius: calculateRem(3);
    width: calculateRem(4);
    height: calculateRem(27);
    padding: 0;
    background-color: $color-primary-light;

    &--disabled {
      background-color: $color-gray;
    }
  }

  &__icon {
    width: calculateRem(41);
    height: calculateRem(41);
    margin-left: calculateRem(10);
    z-index: 2;

    &--border-primary {
      border: 3px solid $color-primary;
    }

    &--border-primary-dark {
      border: 3px solid $color-primary-dark;
    }
  }

  &__liquidity-icon {
    &--green {
      fill: $color-success;
    }

    &--yellow {
      fill: $color-warning;
    }

    &--red {
      fill: $color-error;
    }

    &--disabled {
      fill: $color-gray;
    }
  }

  &__lock-icon {
    fill: $color-success;

    &--disabled {
      fill: $color-gray;
    }
  }

  &__container {
    width: 31%;
    display: flex;
    flex-direction: column;
    margin: 0 calculateRem(16) 0 0;
  }

  &__token-ticker {
    @extend .exrond-text;
    font-weight: 500;
    font-size: calculateEm(14);
    color: $color-white;

    @include lg {
      font-size: calculateRem(16);
    }
  }

  &__token-name {
    @extend .exrond-text;
    font-weight: 500;
    font-size: calculateEm(8);
    color: $color-gray;
    margin-bottom: calculateRem(1);

    @include lg {
      font-size: calculateRem(10);
    }
  }

  &__token-balance {
    @extend .exrond-text;
    font-weight: 400;
    font-size: calculateEm(10);
    color: $color-white;
    margin-top: calculateRem(1);

    @include lg {
      font-size: calculateRem(12);
    }
  }

  &__lock-container {
    width: calculateRem(22);
    height: calculateRem(22);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: calculateRem(-9);

    &--primary {
      background-color: $color-primary;
    }

    &--primary-dark {
      background-color: $color-primary-dark;
    }
  }

  &__text--disabled {
    color: $color-gray;
  }
}
