@import '/src/assets/sass/css-breakpoint-mixins.scss';

.swap-container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;

  @include sm {
    max-width: 540px;
  }

  @include md {
    max-width: 720px;
  }

  @include lg {
    max-width: 1020px;
  }

  @include xl {
    max-width: 1140px;
  }
}
