@include media-breakpoint-down(xl) {
}

@include media-breakpoint-down(lg) {
}

@media (min-width: 991.98px) {
  .exrond-logo-mini {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .exrond-logo {
    display: none;
  }
}

@include media-breakpoint-down(md) {
  .home-container03 {
    height: 100px;
  }

  .exrond-swap-widget {
    flex-direction: column;
  }

  .exrond-swap-card {
    width: 100% !important;
    max-width: 575px;
    margin: 0 auto;
  }

  .swap-svg-wrapper {
    margin-top: 32px;
    margin-bottom: 32px;
  }

  .home-container18 {
    margin: 64px 0;

    .swap-btn-container {
      width: 100%;
      max-width: 575px;
    }

    .btn-swap {
      margin-top: 0 !important;
      width: 100%;
      max-width: 575px;
    }
  }
}

@include media-breakpoint-down(sm) {
}
