.tax-modal {
  .modal-header {
    position: relative;
    margin-top: calculateRem(10);
    margin-bottom: calculateRem(20);
  }

  .modal-title {
    @extend .exrond-text;
    font-size: calculateRem(20);
    color: $color-light-gray;
    font-weight: 500 !important;
    text-align: center;
    width: 100%;
  }

  .modal-content {
    background-color: $color-primary;
    min-width: calculateRem(625);
    border-radius: calculateRem(40);
  }

  &__close {
    @extend .exrond-btn;
    min-width: calculateEm(47);
    min-height: calculateEm(30);
    background-color: $color-primary-dark;
    border-radius: calculateEm(8);
    padding: 0;
    position: absolute;
    right: calculateRem(16);
    top: calculateRem(16);
  }

  &__fee {
    @extend .exrond-text;
    width: 75%;
    height: calculateRem(50);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: calculateEm(12);
    font-weight: 500;
    background-color: $color-primary-dark;
    border-radius: calculateRem(16);
    align-self: center;
  }

  &__mint {
    @extend .exrond-text;
    width: 75%;
    height: calculateRem(50);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: calculateEm(12);
    font-weight: 500;
    background-color: $color-primary;
    border-radius: calculateRem(16);
    border: 1px solid $color-primary-dark;
    align-self: center;
    position: relative;
    cursor: pointer;
  }

  &__exit {
    position: absolute;
    right: calculateRem(10);
  }

  &__buttons {
    display: flex;
    justify-content: center;
    margin-top: calculateRem(35);
  }

  &__confirm {
    @extend .exrond-text;
    font-size: calculateEm(12);
    font-weight: 500;
    height: calculateRem(42);
    width: calculateRem(132);
    background-color: $color-primary-light;
    border-radius: calculateRem(8);
    border: none;
    color: $color-white;
  }

  &__dismiss {
    @extend .exrond-text;
    font-size: calculateEm(12);
    font-weight: 500;
    height: calculateRem(42);
    width: calculateRem(201);
    background-color: $color-primary;
    border: 1px solid $color-primary-dark;
    border-radius: calculateRem(8);
    color: $color-white;
    margin-right: calculateRem(12);

    &--enabled {
      border-color: $color-primary-light;
    }
  }
}
