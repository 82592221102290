@import 'assets/sass/css-breakpoint-mixins.scss';
@import 'assets/sass/variables.module.scss';
@import 'assets/sass/exrond.scss';

.swap-analytics-filter {
  height: calculateRem(36);
  display: flex;
  align-items: center;

  &__title {
    @extend .exrond-text;
    color: $color-gray;
    font-weight: 500;
    font-size: calculateEm(10);
    cursor: pointer;
    display: flex;
    align-items: center;

    &--selected {
      color: $color-white;
    }
  }

  &__icon {
    fill: $color-gray;

    &--selected {
      fill: $color-error;
    }
  }
}
