@import 'assets/sass/css-breakpoint-mixins.scss';
@import 'assets/sass/variables.module.scss';
@import 'assets/sass/exrond.scss';

.swap-search {
  color: $color-white;
  font-family: Montserrat;
  line-height: 1.5;
  font-weight: 500;
  font-size: calculateRem(12);
  outline: none;
  padding-left: calculateRem(12);
  padding-right: calculateRem(12);
  -moz-appearance: textfield;
  appearance: textfield;
  border: 1px solid $color-primary-light;
  border-radius: calculateRem(12);
  caret-color: $color-primary-light;
  height: calculateRem(51);

  &__search-icon {
    fill: $color-primary-light;
  }

  &__title {
    @extend .exrond-text;
    font-size: calculateEm(10);
    color: $color-primary-light;
  }

  &__close-container {
    width: calculateRem(47);
    height: calculateRem(30);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    background-color: $color-primary;
    border-radius: calculateRem(8);
    right: calculateRem(-14);
    top: 50%;
    bottom: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
  }

  &__container {
    position: relative;
    display: flex;
    flex-direction: column;
    margin: calculateRem(10) calculateRem(4) 0 calculateRem(4);

    &__last-element {
      margin-top: calculateRem(6);
      order: -1;
    }
  }

  &--primary {
    background-color: $color-primary;
  }

  &--primary-dark {
    background-color: $color-primary-dark;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &::placeholder,
  &:-ms-input-placeholder,
  &::-ms-input-placeholder {
    color: $color-gray;
    opacity: 1;
  }
}
