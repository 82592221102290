@import '/src/assets/sass/variables.module.scss';
@import '/src/assets/sass/css-breakpoint-mixins.scss';

.select-container {
  border: 1px solid $color-primary-dark;
  border-radius: calculateEm(12);
  min-height: calculateEm(51);

  input {
    font-family: Montserrat;
    line-height: 1.5;
    font-weight: 500;
    font-size: calculateRem(12);
  }

  &.active {
    border-color: $color-primary-light;
  }

  &.error {
    border-color: $color-error;
  }

  .token-option {
    width: calculateEm(41);
    height: calculateEm(41);
  }

  .mark {
    border-top-right-radius: calculateEm(3);
    border-bottom-right-radius: calculateEm(3);
    width: calculateEm(4);
    padding: 0;
    height: 60%;
    background-color: $color-primary-dark;

    &.active {
      background-color: $color-primary-light;
    }

    &.error {
      background-color: $color-error;
    }
  }

  .default-token {
    border-radius: 50%;
  }

  .selector-input,
  :focus-visible {
    background-color: transparent;
    border: none;
    outline: none;
  }
}

.options {
  padding-top: calculateEm(20);
  margin-top: calculateEm(-15);
  border: 1px solid $color-black-12alpha;
  border-radius: 0px 0px calculateEm(12) calculateEm(12);
  border-top: none;

  .divider {
    border-bottom: 1px solid $color-black-12alpha;
    border-radius: 0;
  }
}
