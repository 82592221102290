/* Bootstrap overrides
-------------------------------------------------- */

$body: #000;
$headings-font-weight: 300 !important;
$secondary: #26334a;
$light: #fafafa;

$headings-font-weight: 300 !important;
$a: #1392ff;

/* Do not move the imports from here.
Override Bootstrap variables only above.
-------------------------------------------------- */

// Bootstrap

@import '~bootstrap/scss/bootstrap.scss';

@import '~bootstrap/scss/reboot';

.custom-classname.btn-primary {
  background-color: green;
}

/* Base
-------------------------------------------------- */
html {
  min-height: 100%;
  font-size: 14px;
  font-family: Montserrat !important;
}

.wrapper {
  min-height: 100vh;
}

.opacity-6 {
  opacity: 0.6;
}

.dapp-icon {
  padding: 5px;
  border-radius: 50%;
  background-color: $light;
  width: 65px;
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;

  &.icon-medium {
    width: 80px;
    height: 80px;
  }
}

/* Navbar
-------------------------------------------------- */
.elrond-logo {
  width: 85px;
  height: 25px;
}

.dapp-name {
  line-height: 1;
  border-left: 1px solid $gray-400;
  padding-left: 3px;
  margin-left: 4px;
}

/* Dashboard
-------------------------------------------------- */
.action-btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: $white;
  margin: 0 7px;

  button {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    background-color: $white;

    &:hover {
      opacity: 0.9;
    }
  }
}

.transactions {
  .transaction-icon {
    border-radius: 50%;
    width: 40px;
    height: 40px;
  }

  &.table {
    .transaction-hash .trim {
      @include media-breakpoint-down(xl) {
        max-width: none;
      }

      @include media-breakpoint-down(lg) {
        max-width: 26rem;
      }

      @include media-breakpoint-down(md) {
        max-width: 12rem;
      }

      @include media-breakpoint-down(sm) {
        max-width: 8rem;
      }
    }

    td,
    tr {
      padding: 0.65rem;
    }

    td {
      white-space: nowrap;
      vertical-align: middle;
    }

    tr:last-of-type {
      td {
        border-bottom: 1px solid #dee2e6;
      }
    }
  }
}

/* Actions
-------------------------------------------------- */

.not-allwed {
  cursor: not-allowed;
}

/* Loader, Transaction success/Fail
-------------------------------------------------- */
.page-state {
  .lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
  }

  .lds-ellipsis div {
    position: absolute;
    top: 27px;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.1);
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }

  .bg-blue .lds-ellipsis div {
    background: rgba(255, 255, 255, 0.4);
  }

  .lds-ellipsis div:nth-child(1) {
    left: 6px;
    animation: lds-ellipsis1 0.6s infinite;
  }

  .lds-ellipsis div:nth-child(2) {
    left: 6px;
    animation: lds-ellipsis2 0.6s infinite;
  }

  .lds-ellipsis div:nth-child(3) {
    left: 26px;
    animation: lds-ellipsis2 0.6s infinite;
  }

  .lds-ellipsis div:nth-child(4) {
    left: 45px;
    animation: lds-ellipsis3 0.6s infinite;
  }

  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }

    100% {
      transform: scale(1);
    }
  }

  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }

    100% {
      transform: scale(0);
    }
  }

  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }

    100% {
      transform: translate(19px, 0);
    }
  }
}

/* Footer
-------------------------------------------------- */
footer {
  div {
    margin-top: 0.5rem;
    font-size: 0.75rem;
    display: inline-block;
    font-weight: 400;
    line-height: 1.155rem;

    a {
      color: $black;

      &:hover {
        color: $black;
      }

      #heart {
        height: 16px;
        width: 16px;

        path {
          fill: #c00;
        }
      }
    }
  }
}

.rc-slider {
  position: relative;
  height: 0.875rem;
  padding: 0.3125rem 0;
  width: 100%;
  border-radius: 0.375rem;
  touch-action: none;
  box-sizing: border-box;
  margin-left: 0.4rem;
  margin-right: 0.4rem;
  padding-bottom: 1.5rem;
  width: calc(100% - 0.8rem);
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.rc-slider-rail {
  position: absolute;
  width: 100%;
  background-color: $color-primary;
  height: 0.25rem;
  border-radius: 0.375rem;
}

.rc-slider-track {
  position: absolute;
  left: 0;
  height: 0.25rem;
  border-radius: 0.375rem;
  background-color: $color-primary-light;
}

.rc-slider-handle {
  position: absolute;
  width: 0.875rem;
  height: 0.875rem;
  cursor: pointer;
  cursor: -webkit-grab;
  margin-top: -0.3125rem;
  cursor: grab;
  border-radius: 50%;
  background-color: $color-primary-light;
  touch-action: pan-x;
}

.rc-slider-dot-active {
  background-color: $color-primary-light !important;
  border: 0.1875rem solid $color-primary !important;
}

.rc-slider-dot {
  position: absolute;
  bottom: -0.375rem;
  width: 0.875rem;
  height: 0.875rem;
  // border: 0.1875rem solid $color-primary;
  background-color: $color-primary-light;
  cursor: pointer;
  border-radius: 50%;
  vertical-align: middle;
}

.rc-slider-step {
  position: absolute;
  width: 100%;
  height: 0.25rem;
  background: transparent;
}

.rc-slider-mark {
  position: absolute;
  top: 1.125rem;
  left: 0;
  width: 100%;
  font-size: 0.75rem;
}

.rc-slider-mark-text-active {
  color: #666;
}

.rc-slider-mark-text {
  position: absolute;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  color: #999;
}

.slippage-input {
  background-color: $color-primary;

  &.active {
    background-color: $color-primary-light;
  }
}

// The fix ( connect to a wallet overlap )
.dapp-core-component__dappModalStyles__dappModal {
  z-index: 99999 !important;
}

.dapp-modal-dialog-wrapper {
  z-index: 99998 !important;
}

.c-pointer {
  cursor: pointer;
}

.css-1w1t08m-menu {
  right: 0;
}

.token-icon {
  border-radius: 50%;
  text-align: center;
  color: #4f4f4f;
  background: #f5f5f5;
  box-shadow: inset 0 0 3px rgb(79 79 79 / 20%);

  &.blue-bg {
    background: $color-primary;
  }

  &.dark-blue-bg {
    background: $color-primary;
  }
}

.dapp-core-component__scamPhishingStyles__scam-phishing-alert.column {
  flex-direction: column !important;
}

.lock-icon {
  &__success {
    fill: $color-success;
  }
}
