@import '/src/assets/sass/variables.module.scss';
@import '/src/assets/sass/css-breakpoint-mixins.scss';

.register-dummy-contract .mark--green {
  background-color: $color-success;
}

.register-dummy-contract--first-child {
  border-top-right-radius: calculateEm(8);
  border-top-left-radius: calculateEm(8);
  border-bottom-right-radius: calculateEm(0);
  border-bottom-left-radius: calculateEm(0);
}

.register-dummy-contract--second-child {
  border-top-right-radius: calculateEm(0);
  border-top-left-radius: calculateEm(0);
  border-bottom-right-radius: calculateEm(8);
  border-bottom-left-radius: calculateEm(8);
}
