:root {
  --dl-size-size-large: 144px;
  --dl-size-size-small: 48px;
  --dl-size-size-medium: 96px;
  --dl-size-size-xlarge: 192px;
  --dl-size-size-xsmall: 16px;
  --dl-space-space-unit: 16px;
  --dl-size-size-xxlarge: 288px;
  --dl-color-scheme-white: #ffffff;
  --dl-size-size-maxwidth: 1144px;
  --dl-radius-radius-round: 50%;
  --dl-color-scheme-green80: #105749ff;
  --dl-space-space-halfunit: 8px;
  --dl-space-space-sixunits: 96px;
  --dl-space-space-twounits: 32px;
  --dl-color-scheme-green100: #0c4237ff;
  --dl-color-scheme-orange80: #ffc78bff;
  --dl-color-scheme-yellow20: #fffef3ff;
  --dl-color-scheme-yellow80: #fff6a7ff;
  --dl-radius-radius-radius2: 2px;
  --dl-radius-radius-radius4: 4px;
  --dl-radius-radius-radius8: 8px;
  --dl-space-space-fiveunits: 80px;
  --dl-space-space-fourunits: 64px;
  --dl-color-scheme-orange100: #ffad61ff;
  --dl-radius-radius-radius16: 16px;
  --dl-space-space-eightunits: 128px;
  --dl-space-space-threeunits: 48px;
  --dl-color-scheme-lightgreen: #c7ff9bff;
  --dl-space-space-oneandhalfunits: 24px;
}

.button {
  color: var(--dl-color-scheme-green100);
  cursor: pointer;
  display: inline-block;
  padding: 12px 24px;
  border-color: var(--dl-color-scheme-green100);
  border-width: 2px;
  border-radius: 32px;
  background-color: var(--dl-color-scheme-white);
}

.input {
  color: var(--dl-color-scheme-green100);
  cursor: auto;
  padding: 0.5rem 1rem;
  border-color: var(--dl-color-scheme-green100);
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--dl-color-scheme-white);
}

.textarea {
  color: var(--dl-color-scheme-green100);
  cursor: auto;
  padding: 0.5rem;
  border-color: var(--dl-color-scheme-green100);
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--dl-color-scheme-white);
}

.list {
  width: 100%;
  margin: 1em 0px 1em 0px;
  display: block;
  padding: 0px 0px 0px 1.5rem;
  list-style-type: none;
  list-style-position: outside;
}

.list-item {
  display: list-item;
}

.teleport-show {
  display: flex !important;
}

.button-secondary {
  font-style: normal;
  transition: 0.3s;
  font-family: Lexend;
  font-weight: 500;
  line-height: 1;
  border-color: var(--dl-color-scheme-green80);
  margin-right: var(--dl-space-space-unit);
}

.button-secondary:hover {
  color: var(--dl-color-scheme-white);
  background-color: var(--dl-color-scheme-orange100);
}

.button-primary {
  color: var(--dl-color-scheme-white);
  font-style: normal;
  transition: 0.3s;
  font-family: Lexend;
  font-weight: 500;
  line-height: 1;
  border-color: var(--dl-color-scheme-green80);
  background-color: var(--dl-color-scheme-green80);
}

.button-primary:hover {
  color: var(--dl-color-scheme-green80);
  background-color: var(--dl-color-scheme-lightgreen);
}

// .navbar-container {
//   top: 0;
//   width: 100%;
//   display: flex;
//   z-index: 1000;
//   position: sticky;
//   padding-top: var(--dl-space-space-oneandhalfunits);
//   padding-bottom: var(--dl-space-space-oneandhalfunits);
//   justify-content: center;
//   // background-color: var(--dl-color-scheme-white);
// }
.navbar-burger-menu {
  display: none;
}

.max-width {
  width: 100%;
  display: flex;
  max-width: 1300px;
  align-items: center;
  padding-left: var(--dl-space-space-halfunit);
  padding-right: var(--dl-space-space-halfunit);
  justify-content: space-between;
}

.button-gradient {
  display: flex;
  font-size: 18px;
  background: linear-gradient(90deg, #c7ff9b 0%, #fff6a7 100%);
  font-style: normal;
  transition: 0.3s;
  align-items: center;
  font-weight: 700;
  line-height: 0.9;
  padding-top: 20px;
  border-color: transparent;
  margin-right: var(--dl-space-space-unit);
  padding-left: 32px;
  padding-right: 32px;
  padding-bottom: 20px;
}

.button-gradient:hover {
  color: var(--dl-color-scheme-white);
  background: unset;
  border-color: var(--dl-color-scheme-white);
}

.button-transparent {
  color: var(--dl-color-scheme-white);
  font-size: 18px;
  font-style: normal;
  transition: 0.3s;
  font-weight: 700;
  line-height: 0.9;
  padding-top: 20px;
  border-color: var(--dl-color-scheme-white);
  padding-left: 32px;
  padding-right: 32px;
  padding-bottom: 20px;
  background-color: transparent;
}

.button-transparent:hover {
  color: var(--dl-color-scheme-green80);
  background-color: var(--dl-color-scheme-lightgreen);
}

.section-container {
  width: 100%;
  display: flex;
  overflow: hidden;
  align-items: center;
  padding-top: var(--dl-space-space-eightunits);
  flex-direction: column;
}

.hero-container {
  padding-top: 0 !important;
  background-color: var(--dl-color-scheme-green80);
}

.bg-transparent {
  background-color: transparent;
}

.question-content {
  width: 100%;
  display: flex;
  overflow: hidden;
  max-height: 0;
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
}

.segment {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 4px;
  border-color: rgba(44, 39, 33, 0.08);
  border-width: 1px;
  padding-left: 15px;
  border-radius: 12px;
  margin-bottom: var(--dl-space-space-halfunit);
  padding-right: 15px;
  flex-direction: column;
  padding-bottom: 4px;
  justify-content: space-between;
  background-color: var(--dl-color-scheme-white);
}

.footer-column {
  width: 20rem;
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  justify-content: flex-start;
}

.button1 {
  flex: 0 0 auto;
  color: var(--dl-color-scheme-white);
  display: flex;
  font-size: 0.7em;
  font-style: normal;
  align-items: center;
  font-family: Montserrat;
  font-weight: 400;
  padding-top: 10px;
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: 10px;
  background-color: $color-primary-light;
}

.button1_hover {
  flex: 0 0 auto;
  color: var(--dl-color-scheme-white);
  display: flex;
  font-size: 0.7em;
  font-style: normal;
  transition: 0.3s;
  align-items: center;
  font-family: Montserrat;
  font-weight: 400;
  padding-top: 10px;
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: 10px;
  background-color: $color-primary-light;
}

.button1_hover:hover {
  background-color: #007bff;
}

.button2 {
  flex: 0 0 auto;
  color: var(--dl-color-scheme-white);
  height: 40px;
  display: flex;
  font-size: 0.7em;
  box-shadow: 0px 0px 10px 0px rgba(117, 189, 22, 0.5);
  font-style: normal;
  align-items: center;
  font-family: Montserrat;
  font-weight: 400;
  margin-left: var(--dl-space-space-unit);
  padding-top: 6px;
  border-color: #75bd16;
  border-style: solid;
  border-width: 1px;
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: 6px;
}

.button2_hover {
  flex: 0 0 auto;
  color: var(--dl-color-scheme-white);
  height: 40px;
  display: flex;
  font-size: 0.7em;
  box-shadow: 0px 0px 10px 0px rgba(117, 189, 22, 0.5);
  font-style: normal;
  transition: 0.3s;
  align-items: center;
  font-family: Montserrat;
  font-weight: 400;
  margin-left: var(--dl-space-space-unit);
  padding-top: 6px;
  border-color: #75bd16;
  border-style: solid;
  border-width: 1px;
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: 6px;
}

.button2_hover:hover {
  color: #043119;
  font-weight: 500;
  border-color: transparent;
  background-color: #aae65d;
}

.button3 {
  flex: 0 0 auto;
  color: #043119;
  width: 50rem;
  display: flex;
  font-size: 1.3em;
  box-shadow: 0px 20px 30px 10px rgba(20, 30, 47, 0.5);
  font-style: normal;
  align-items: center;
  font-family: Montserrat;
  font-weight: 700;
  padding-top: 15px;
  border-radius: 20px;
  flex-direction: column;
  padding-bottom: 15px;
  justify-content: center;
  background-image: linear-gradient(90deg,
      rgb(19, 234, 117) 2%,
      rgb(19, 193, 100) 100%);
}

.button3_hover {
  flex: 0 0 auto;
  color: #043119;
  width: 50rem;
  display: flex;
  font-size: 1.3em;
  box-shadow: 0px 20px 30px 10px rgba(20, 30, 47, 0.5);
  font-style: normal;
  transition: 0.3s;
  align-items: center;
  font-family: Montserrat;
  font-weight: 700;
  padding-top: 15px;
  border-radius: 20px;
  flex-direction: column;
  padding-bottom: 15px;
  justify-content: center;
  background-image: linear-gradient(90deg,
      rgb(19, 234, 117) 2%,
      rgb(19, 193, 100) 100%);
}

.button3_hover:hover {
  color: var(--dl-color-scheme-white);
  background-image: linear-gradient(90deg,
      rgb(19, 193, 100) 2%,
      rgb(19, 234, 117) 100%);
}

.text1 {
  color: var(--dl-color-scheme-white);
  font-size: 0.9em;
  font-style: normal;
  font-family: Montserrat;
  font-weight: 500;
  line-height: 1.5;
  margin-right: var(--dl-space-space-threeunits);
  text-transform: none;
  text-decoration: none;
}

.text2 {
  color: var(--dl-color-scheme-white);
  font-size: 0.7em;
  font-style: normal;
  font-family: Montserrat;
  font-weight: 500;
  line-height: 1.5;
  margin-right: 20px;
  text-transform: none;
  text-decoration: none;
}

.text2_hover {
  color: var(--dl-color-scheme-white);
  font-size: 0.7em;
  font-style: normal;
  transition: 0.3s;
  font-family: Montserrat;
  font-weight: 500;
  line-height: 1.5;
  margin-right: 20px;
  text-transform: none;
  text-decoration: none;
}

.text2_hover:hover {
  color: $color-primary-light;
}

.text1_hover {
  color: var(--dl-color-scheme-white);
  font-size: 0.9em;
  font-style: normal;
  transition: 0.3s;
  font-family: Montserrat;
  font-weight: 500;
  line-height: 1.5;
  margin-right: var(--dl-space-space-threeunits);
  text-transform: none;
  text-decoration: none;
}

.text1_hover:hover {
  color: $color-primary-light;
}

.before-Heading {
  font-size: 14px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 800;
  line-height: 1.15;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  text-decoration: none;
}

.brand-Name {
  font-size: 20px;
  font-style: normal;
  font-family: Urbanist;
  font-weight: 800;
  line-height: 1.2;
  letter-spacing: 0.25em;
  text-transform: uppercase;
  text-decoration: none;
}

.navbar-Link {
  font-size: 16px;
  font-style: normal;
  font-family: Titillium Web;
  font-weight: 600;
  line-height: 1.5;
  text-transform: none;
  text-decoration: none;
}

.content {
  font-size: 16px;
  font-family: Titillium Web;
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}

@media (max-width: 991px) {
  .button2 {
    margin-left: 0px;
    margin-right: var(--dl-space-space-threeunits);
  }

  .button2_hover {
    margin-left: 0px;
    margin-right: var(--dl-space-space-threeunits);
  }

  .button3 {
    width: 60%;
  }

  .button3_hover {
    width: 60%;
  }
}

@media (max-width: 767px) {
  .navbar-burger-menu {
    display: flex;
  }

  .section-container {
    padding-top: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-oneandhalfunits);
  }

  .footer-column {
    width: 15rem;
  }

  .button3 {
    width: 80%;
  }

  .button3_hover {
    width: 80%;
  }
}

@media (max-width: 479px) {
  .footer-column {
    width: 10rem;
  }

  .button1 {
    margin-left: var(--dl-space-space-unit);
    padding-top: 3px;
    padding-left: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
    padding-bottom: 3px;
    justify-content: center;
  }

  .button1_hover {
    margin-left: var(--dl-space-space-unit);
    padding-top: 3px;
    padding-left: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
    padding-bottom: 3px;
    justify-content: center;
  }

  .button2 {
    height: 25px;
    padding-top: 3px;
    margin-right: 0px;
    padding-left: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
    padding-bottom: 3px;
    justify-content: center;
  }

  .button2_hover {
    height: 25px;
    padding-top: 3px;
    margin-right: 0px;
    padding-left: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
    padding-bottom: 3px;
    justify-content: center;
  }

  .button3 {
    width: 95%;
    font-size: 1em;
    box-shadow: 0px 5px 10px 5px rgba(20, 30, 47, 0.5);
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
  }

  .button3_hover {
    width: 95%;
    font-size: 1em;
    box-shadow: 0px 5px 10px 5px rgba(20, 30, 47, 0.5);
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
  }
}

.home-footer {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-fourunits);
  border-color: #2b3952;
  border-width: 1px;
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: center;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}

.home-container21 {
  width: 100%;
  display: flex;
  max-width: 1300px;
  align-items: flex-start;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  justify-content: space-between;
}

.home-image6 {
  width: 150px;
  object-fit: contain;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
  text-decoration: none;
}

.home-text38 {
  color: #b4bcc3;
  font-size: 0.9em;
  font-style: normal;
  font-family: Montserrat;
  font-weight: 300;
  margin-bottom: 0;
}

.home-container23 {
  flex: 0 0 auto;
  display: flex;
  margin-top: var(--dl-space-space-halfunit);
  align-items: center;
}

.home-icon11 {
  fill: #b4bcc3;
  width: 22px;
  height: 22px;
  transition: 0.3s;
  margin-right: var(--dl-space-space-twounits);
}

.home-icon11:hover {
  fill: var(--dl-color-scheme-white);
}

.home-icon13 {
  fill: #b4bcc3;
  width: 18px;
  height: 18px;
  transition: 0.3s;
}

.home-icon13:hover {
  fill: var(--dl-color-scheme-white);
}

.home-container03 {
  flex: 0 0 auto;
  width: 100%;
  height: 150px;
  display: flex;
  align-items: flex-start;
}

.home-container18 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: 100px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.home-text13 {
  color: var(--dl-color-scheme-white);
  font-size: 1em;
  font-style: normal;
  font-family: Montserrat;
  font-weight: 600;
  line-height: 1.5;
  margin-right: var(--dl-space-space-halfunit);
}

.home-text14 {
  color: #b4bcc3;
  font-size: 0.9em;
  font-style: normal;
  font-family: Montserrat;
  font-weight: 600;
  line-height: 1.5;
}

.loading-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
  // font-weight: 600;
  color: #ffffff;

  .icon {
    color: $color-primary-light;
    animation: spin 2s linear infinite;
    margin-bottom: 15px !important;

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(360deg);
      }
    }
  }
}

.spinner {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
  // font-weight: 600;

  .icon {
    color: #ffffff;
    animation: spin 2s linear infinite;

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(360deg);
      }
    }
  }
}

.swap-btn-container {
  flex: 0 0 auto;
  width: 50rem;
  display: flex;
  font-size: 1.3em;
  box-shadow: 0px 20px 30px 10px rgba(20, 30, 47, 0.5);
  font-style: normal;
  transition: 0.3s;
  align-items: center;
  font-family: Montserrat;
  font-weight: 700;
  padding-top: 15px;
  border-radius: 20px;
  flex-direction: row;
  padding-bottom: 15px;
  justify-content: center;
}

.exrond-connect-btn {
  min-width: 100px;
}

.exrond-admin-btn {
  min-width: 100px;
  font-style: normal;
  font-family: Montserrat;
  font-weight: 400;
  padding: 10px 24px;
  background-color: transparent;
  border-radius: 8px;
}

.exrond-settings-card {
  font-size: 1rem;
  min-width: 400px;
  border-radius: 32px;
  padding: 32px;
  box-shadow: 0px 20px 30px 10px rgb(20 30 47 / 30%);

  .popover-body {
    color: #ffffff;
  }

  .title {
    color: #b4bcc3;
    font-size: 1.3em;
    font-style: normal;
    font-family: Montserrat;
    font-weight: 600 !important;
  }

  .btn {
    color: #ffffff;
    background-color: $color-primary-dark;
    border-radius: 12px;
    padding: 10px;

    &.active {
      background-color: $color-primary-light;
    }
  }
}