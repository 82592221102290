@import '/src/assets/sass/variables.module.scss';

.bluechip-footer {
  background-color: transparent;
  border-radius: 0;
  margin-top: 0;
  padding-top: 0;
  box-shadow: none;
  border-top: 1px solid $color-black-12alpha;
}
