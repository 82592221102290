@import 'assets/sass/css-breakpoint-mixins.scss';
@import 'assets/sass/variables.module.scss';
@import 'assets/sass/exrond.scss';

.swap-header {
  height: calculateRem(39);
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid $color-black-12alpha;
  border-top: 1px solid $color-black-12alpha;
  position: sticky;
  top: -1px;
  z-index: 3;

  @include lg {
    top: 0;
  }

  &--primary {
    background-color: $color-primary;
  }

  &--primary-dark {
    background-color: $color-primary-dark;
  }

  &--borderless-top {
    border-top: 0;
  }

  &__text {
    @extend .exrond-text;
    font-weight: 500;
    font-size: calculateEm(10);
    color: $color-white;
    margin-right: calculateRem(6);
    margin-left: calculateRem(12);
  }
}
