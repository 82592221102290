.tax-mobile-page {
  background-color: $color-primary-dark;
  height: 100dvh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .mobile-page-title {
    @extend .exrond-text;
    font-size: calculateRem(16);
    color: $color-light-gray;
    font-weight: 500 !important;
    text-align: center;
    width: 100%;
  }

  &__content {
    background-color: $color-primary;
    width: calculateRem(350);
    border-radius: calculateRem(8);
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__fee {
    @extend .exrond-text;
    color: $color-white;
    width: 90%;
    height: calculateRem(50);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: calculateEm(12);
    font-weight: 500;
    background-color: $color-primary-dark;
    border-radius: calculateRem(16);
    align-self: center;
  }

  &__mint {
    @extend .exrond-text;
    color: $color-white;
    width: 90%;
    height: calculateRem(50);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: calculateEm(12);
    font-weight: 500;
    background-color: $color-primary;
    border-radius: calculateRem(16);
    border: 1px solid $color-primary-dark;
    align-self: center;
    position: relative;
    cursor: pointer;
  }

  &__exit {
    position: absolute;
    right: calculateRem(10);
  }

  &__no-exr-message {
    color: $color-white;
  }

  &__buttons {
    width: calculateRem(330);
    height: fit-content;
  }

  &__back {
    @extend .exrond-text;
    font-size: calculateEm(12);
    font-weight: 500;
    height: calculateRem(42);
    width: 30%;
    background-color: $color-primary;
    border-radius: calculateRem(8) 0 0 calculateRem(8);
    margin: 0;
    border: none;
    color: #667183;
  }

  &__confirm {
    @extend .exrond-text;
    font-size: calculateEm(12);
    font-weight: 500;
    width: 70%;
    height: calculateRem(42);
    background-color: $color-primary-light;
    border-radius: 0 calculateRem(8) calculateRem(8) 0;
    margin: 0;
    border: none;
    color: $color-white;
  }

  &__dismiss {
    @extend .exrond-text;
    font-size: calculateEm(12);
    font-weight: 500;
    height: calculateRem(42);
    width: calculateRem(325);
    background-color: transparent;
    border: 1px solid #667183;
    border-radius: calculateRem(8);
    color: #667183;
    width: 100%;

    &--enabled {
      border-color: $color-primary-light;
      color: $color-white;
    }
  }
}
