@import '/src/assets/sass/variables.module.scss';
@import '/src/assets/sass/css-breakpoint-mixins.scss';
@import '/src/assets/sass/exrond.scss';

.mint {
  &__container {
    background: linear-gradient(270deg, #0029ff 0%, #3a7dff 100%);
    border: 1px solid #6beafd;
    border-radius: 20px;
    width: 170px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-height: 122px;
    z-index: 1;

    @include lg {
      width: 276px;
      padding: 19px;
    }

    &--mobile-expanded {
      width: 100%;
    }

    &--mobile-mini {
      width: 100%;
    }
  }

  &__btn-nfts {
    @extend .exrond-text;
    background-color: #2a262c;
    border: calculateRem(1) solid rgba(0, 0, 0, 0.15);
    font-weight: 700;
    width: calculateRem(32);
    height: calculateRem(32);
    font-size: calculateRem(10);
    border-radius: calculateRem(8);
    color: $color-gray;
    padding: calculateRem(2);

    @include lg {
      font-size: calculateRem(14);
    }

    &--selected {
      background-color: #6beafd;
      color: $color-black;
    }
  }

  &__main-btn {
    background: #6beafd !important;
    font-size: calculateRem(10);
    line-height: calculateRem(17);
    position: initial;
    color: black !important;
    height: calculateRem(32);
    box-shadow: -4px 4px 10px rgba(0, 0, 0, 0.25);
    border-radius: 10px !important;
    border: none;
    width: 100%;

    &:disabled {
      opacity: 0.8;
    }

    @include lg {
      font-size: calculateRem(14);
    }

    &__connect {
      display: flex;
      justify-content: center;
      text-align: center;
      align-items: center;
      padding: 0;

      svg {
        fill: black !important;
      }
    }
  }

  &__input {
    @extend .exrond-text;
    background-color: #2a262c;
    border: calculateRem(1) solid rgba(0, 0, 0, 0.15);
    font-weight: 700;
    height: calculateRem(32);
    font-size: calculateRem(12);
    border-radius: calculateRem(8);
    color: $color-white;
    width: calculateRem(89);
    text-align: center;

    &--selected {
      background-color: #6beafd;
      color: $color-black;
    }

    &:focus-visible {
      outline: none;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &[type='number'] {
      -moz-appearance: textfield;
    }
  }
}
