@import './variables.module.scss';

.card.card-rewards {
  box-shadow: 0px 20px 30px 10px rgb(20 30 47 / 30%);
  border-radius: 0px;
  background-color: $color-primary-dark;
  color: #fff;
  z-index: 1;
  padding: 0;

  .mark {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    min-width: 6px;
  }
}

.reward-border {
  border-style: solid;
  border-width: 1px;

  @include lg {
    border-width: 0px;
  }
}

.reward-claim-all-button {
  @extend .reward-header-button;
  border-radius: 8px !important;
  background: $color-primary-light !important;

  @include lg {
    border-bottom-left-radius: 0px !important;
    border-top-left-radius: 0px !important;
    border-bottom-right-radius: 8px !important;
    border-top-right-radius: 8px !important;
  }
}

.reward-claimable-button {
  @extend .reward-header-button;
  border-bottom-left-radius: 8px !important;
  border-top-left-radius: 8px !important;
  border-bottom-right-radius: 0px !important;
  border-top-right-radius: 0px !important;
  background: $color-primary !important;
}

.reward-header-button {
  @extend .exrond-btn;
  width: 100%;
  padding: 12px 0px 12px 0px !important;
  font-size: 0.9rem !important;
  font-weight: 500 !important;
  text-align: center !important;
  color: $color-white !important;

  @include lg {
    width: 50% !important;
  }
}

.rewards-filter-button {
  min-width: 76px;
  height: 40px;
  font-size: 0.7rem;
  border-radius: 8px !important;

  @include sm {
    min-width: 95px;
    height: 40px;
    font-size: 0.8rem;
  }

  @include md {
    font-size: 0.9rem;
  }

  @include lg {
    min-width: 100px;
    height: 42px;
    font-size: 0.9rem;
  }
}

.token-icon.reward-token-icon {
  width: 50px;
  height: 50px;

  @include lg {
    width: 60px;
    height: 60px;
  }
}

.reward-card-title {
  font-size: 0.9rem;
  font-weight: 500;
}

.reward-card-subtitle {
  font-size: 0.8rem;
  font-weight: 400;
}

.reward-button {
  @extend .exrond-btn;
  border-radius: 8px !important;
  font-size: 0.9rem !important;
  font-weight: 500 !important;
  padding: 12px 0px 12px 0px !important;
  width: 100% !important;

  @include lg {
    width: 100px !important;
  }
}

.faucet-container {
  border-radius: 0px !important;

  > :first-child {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  > :last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}

.claim-all-button-logo {
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  background: $color-primary;
}

.sticky-faucet-button {
  padding: 25px;
  height: 115px;
  position: sticky;
  z-index: 1;
  bottom: 0;
  align-items: center;
}

.faucet-name {
  width: auto;

  @include lg {
    width: 100%;
  }
}
