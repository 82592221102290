@import '/src/assets/sass/variables.module.scss';

.my-liquidity-item {
  margin: 1rem;
  background-color: $color-primary;
  border-radius: 1.25rem;

  .exrond-pool-icon.icon-lg .exrond-token-symbol + .exrond-token-symbol {
    margin-left: 1.3rem;
    margin-top: -0.725rem;
  }
  .exrond-pool-icon.icon-lg .exrond-token-symbol {
    width: 2.3rem;
    height: 2.3rem;
  }

  .exrond-token-symbol {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    box-shadow: 0 1px 2px rgb(0 0 0 / 8%);
  }

  &.active {
    .card-header,
    .card-body {
      border-bottom: 2px solid $color-primary-dark;
    }
  }
}
.btn-remove {
  border-radius: 12px;
}
