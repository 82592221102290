@import 'assets/sass/css-breakpoint-mixins.scss';
@import 'assets/sass/variables.module.scss';
@import 'assets/sass/exrond.scss';

.swap-actions-container {
  width: 100%;
  margin-top: 20px;

  @include lg {
    width: 50rem;
    margin: 0 auto;
    margin-top: 100px;
    position: initial;
  }
}