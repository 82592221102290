@import 'assets/sass/css-breakpoint-mixins.scss';
@import 'assets/sass/variables.module.scss';
@import 'assets/sass/exrond.scss';

.swap-analytics-token {
  &__icon {
    height: calculateRem(35);
    width: calculateRem(35);
  }

  &__title {
    @extend .exrond-text;
    font-weight: 500;
    font-size: calculateEm(8);
    color: $color-white;
  }
}
