@import '/src/assets/sass/variables.module.scss';

.navbar-wrapper {
  padding-top: 48px;
  padding-left: 3vw;
  padding-right: 3vw;

  .navbar-container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .exrond_navbar {
      background-color: $color-primary;
      border-radius: 25px;
      padding: 12px 32px;
      .item {
        padding-left: 1rem;
        padding-right: 1rem;

        a {
          color: white !important;
          font-size: 0.9em;
          font-style: normal;
          font-family: 'Montserrat';
          font-weight: 500;
          line-height: 1.5;
          text-transform: none;
          text-decoration: none;
        }
        &:hover {
          a {
            cursor: pointer;
            color: $color-primary-light !important;
          }
        }
        .active {
          color: $color-primary-light !important;
        }
      }
    }
    .link-wrapper {
      .mex-cash-button {
        color: white;
        height: 40px;
        display: flex;
        box-shadow: 0px 0px 10px 0px rgba(117, 189, 22, 0.5);
        transition: 0.3s;
        align-items: center;
        margin-left: 16px;
        padding-top: 6px;
        border-color: #75bd16;
        border-style: solid;
        border-width: 1px;
        padding-left: 16px;
        border-radius: 8px;
        padding-right: 16px;
        padding-bottom: 6px;

        .image-symbol {
          width: 17px;
          object-fit: contain;
          margin-right: 8px;
        }

        span {
          font-size: 0.7em;
          font-style: normal;
          font-family: Montserrat;
          font-weight: 400;
        }

        &:hover {
          color: #043119;
          border-color: transparent;
          background-color: #aae65d;
        }
      }

      &:hover {
        text-decoration: none;
      }
    }
  }
}

.connect-button-wrapper {
  // width: 200px;
  object-fit: contain;
  a {
    float: right;
  }
}

.navbar-wrapper.mobile {
  padding: 3vw;
}

.navbar-container.mobile {
  .nav {
    justify-content: center;
    flex-direction: column;

    .nav-item {
      text-align: center;
      background-color: rgb(39, 52, 74);
      margin: 1px 0;

      a {
        display: block;
        padding: 2rem;
        color: white;
      }

      &:hover {
        a {
          cursor: pointer;
          color: $color-primary-light !important;
        }
      }
      .active {
        color: $color-primary-light !important;
      }
    }
  }
}

.expanded-navbar {
  background-color: #1c2738;
  height: 100vh;
  width: 100%;
  position: fixed;
  z-index: 100;
  overflow: hidden;
}

.submenu-item {
  margin: 0;
  border: 1px solid #00000020;
  padding: 0;
}
