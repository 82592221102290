@import '/src/assets/sass/variables.module.scss';
@import '/src/assets/sass/css-breakpoint-mixins.scss';

.token-option-container {
  height: calculateEm(51);

  .mark {
    border-top-right-radius: calculateEm(3);
    border-bottom-right-radius: calculateEm(3);
    width: calculateEm(4);
    height: 60%;
    padding: 0;
    background-color: $color-primary-light;
  }

  .token-option {
    width: calculateEm(41);
    height: calculateEm(41);
  }
}
