@import '/src/assets/sass/variables.module.scss';
@import '/src/assets/sass/css-breakpoint-mixins.scss';

.deploy__input-container {
  min-height: calculateEm(51);
  border-radius: calculateEm(12);
  border: 1px solid $color-primary-dark;
  position: relative;
}

.deploy__input-container--centered {
  justify-content: center;
}

.deploy__input-container--active {
  border-color: $color-primary-light;
}

.deploy__input {
  color: $color-white;
  font-family: Montserrat;
  line-height: 1.5;
  font-weight: 500;
  font-size: calculateRem(12);
  outline: none;
  padding-left: calculateEm(12);
  padding-right: -20px;
  -moz-appearance: textfield;
  appearance: textfield;
  background-color: transparent;
  border: none;
  min-width: calculateEm(1);
}

.deploy__input--hidden {
  display: none;
}

.deploy__input::-webkit-outer-spin-button,
.deploy__input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.deploy__input[type='number'] {
  -moz-appearance: textfield;
}
