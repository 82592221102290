@import '/src/assets/sass/variables.module.scss';
@import '/src/assets/sass/css-breakpoint-mixins.scss';

.liquid-vesting {
  &-container {
    position: relative;
    margin-top: calculateRem(-113);

    @include lg {
      margin-top: calculateRem(-287);
    }
  }

  &-loader .fa-spin {
    color: white;
  }
}
