@import 'assets/sass/css-breakpoint-mixins.scss';
@import 'assets/sass/variables.module.scss';
@import 'assets/sass/exrond.scss';

.swap-select {
  border: 1px solid $color-primary-light;
  border-radius: calculateEm(12);
  min-height: calculateEm(51);
  user-select: none;
  z-index: 3;
  position: relative;
  cursor: pointer;

  &__open {
    border-color: $color-gray;
  }

  &__options-list {
    max-height: calculateRem(379);
    overflow: auto;

    &--expanded {
      max-height: calc(100vh - 440px);
    }

    &--expanded-search {
      max-height: calc(100vh - 375px);
    }

    &::-webkit-scrollbar {
      width: calculateRem(10);
    }

    &::-webkit-scrollbar-track {
      -webkit-border-bottom-right-radius: 5px;
      border-bottom-right-radius: calculateRem(12);
    }

    &--primary {
      &::-webkit-scrollbar-track {
        background: $color-primary-dark;
      }

      &::-webkit-scrollbar-thumb {
        background: $color-primary;
      }
    }

    &--primary-dark {
      &::-webkit-scrollbar-track {
        background: $color-primary;
      }

      &::-webkit-scrollbar-thumb {
        background: $color-primary-dark;
      }
    }
  }

  &__placeholder {
    @extend .exrond-text;
    font-weight: 500;
    font-size: calculateRem(10);
    color: $color-white;
    width: 100%;
    margin-left: calculateRem(20);

    @include lg {
      font-size: calculateRem(10);
    }
  }

  &--primary {
    background-color: $color-primary;
  }

  &--primary-dark {
    background-color: $color-primary-dark;
  }

  &__ic-right {
    position: absolute;
    right: calculateRem(10);
  }

  &__options {
    z-index: 2;
    width: 100%;
    margin-top: calculateRem(-8);
    padding-top: calculateRem(8);
    padding-bottom: calculateRem(4);
    border: 1px solid $color-black-12alpha;
    border-radius: 0px 0px calculateEm(12) calculateEm(12);
    border-top: none;
    display: flex;
    flex-direction: column;
    position: absolute;

    &--expanded {
      position: initial;
      flex-direction: column-reverse;
    }

    .divider {
      border-bottom: 1px solid $color-black-12alpha;
      border-radius: 0;
    }
  }
}

.swap-svg {
  &--primary {
    fill: $color-primary;
  }

  &--primary-dark {
    fill: $color-primary-dark;
  }
}

.swap-token-icon {
  border-radius: 50%;

  &--primary {
    background-color: $color-primary;
  }

  &--primary-dark {
    background-color: $color-primary-dark;
  }
}
