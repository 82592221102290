@import 'assets/sass/css-breakpoint-mixins.scss';
@import 'assets/sass/variables.module.scss';
@import 'assets/sass/exrond.scss';

.swap-amount {
  padding-left: calculateRem(12);
  padding-right: calculateRem(8);
  border: 1px solid $color-gray;
  border-radius: calculateRem(12);
  height: calculateRem(51);
  margin-top: calculateRem(60);
  position: relative;

  &--primary {
    background-color: $color-primary;
  }

  &--primary-dark {
    background-color: $color-primary-dark;
  }

  &__balance-container {
    min-width: 23%;
    display: flex;
    flex-direction: column;
  }

  &__balance {
    @extend .exrond-text;
    font-weight: 500;
    font-size: calculateEm(8);
    color: $color-gray;
    margin-bottom: calculateRem(1);

    @include lg {
      font-size: calculateRem(10);
    }
  }

  &__amount {
    @extend .exrond-text;
    font-weight: 400;
    font-size: calculateEm(10);
    color: $color-white;
    margin-top: calculateRem(1);

    @include lg {
      font-size: calculateRem(12);
    }
  }

  &__max {
    @extend .exrond-text;
    font-weight: 700;
    font-size: calculateEm(10);
    color: $color-success;
    cursor: pointer;

    &--disabled {
      opacity: 0.6;
      cursor: default;
    }
  }

  &__settings {
    box-sizing: content-box;
    right: calculateRem(10);
  }

  &__input {
    color: $color-white;
    font-family: Montserrat;
    line-height: 1.5;
    font-weight: 500;
    font-size: calculateRem(10);
    outline: none;
    border: none;
    -moz-appearance: textfield;
    appearance: textfield;
    caret-color: $color-primary-light;
    background: transparent;

    @include lg {
      font-size: calculateRem(12);
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &::placeholder,
    &:-ms-input-placeholder,
    &::-ms-input-placeholder {
      color: $color-gray;
      opacity: 1;
    }

    &--disabled {
      color: $color-gray;
    }
  }
}
