@import '/src/assets/sass/variables.module.scss';
@import '/src/assets/sass/css-breakpoint-mixins.scss';

.swap-svg-wrapper {
  height: fit-content;
  border-radius: calculateRem(16);
  background-color: $color-primary-dark;
  width: calculateRem(50);
  height: calculateRem(50);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  border: 1px solid #141e2f;
  box-shadow: 0px 20px 30px 10px rgba(22, 22, 41, 0.3);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
  margin: 0 !important;

  @include lg {
    border-radius: calculateRem(20);
    background-color: $color-primary;
    width: calculateRem(66);
    height: calculateRem(65);
    box-shadow: 0px 20px 30px 10px rgba(20, 30, 47, 0.5);
  }

  &:hover {
    background-color: #13ea75;

    .swap-svg {
      fill: $color-primary;
    }
  }

  .swap-svg {
    fill: #13ea75;
    width: 35px;
    height: 35px;
    transform: rotateZ(90deg);
  }
}
