@import 'assets/sass/css-breakpoint-mixins.scss';
@import 'assets/sass/variables.module.scss';
@import 'assets/sass/exrond.scss';

.swap-button-container {
  display: flex;
  flex-direction: row-reverse;
  position: relative;
  max-width: calculateRem(350);
  margin: 0 auto;
  height: calculateRem(42);

  @include lg {
    height: calculateRem(50);
    max-width: 100%;
    flex-direction: row;
  }
}

.swap-button {
  flex-grow: 1;
  cursor: pointer;
  transition: 0.3s;
  border: none !important;
  border-radius: calculateEm(16);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  text-decoration: none;
  color: $color-primary;
  background-color: $color-success;
  font-size: calculateRem(12);
  font-weight: 600;
  padding: calculateEm(10);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  @include lg {
    border-radius: calculateEm(16);
    font-size: calculateRem(18.2);
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &-connect {
    width: 95%;
  }
}

.settings-button {
  color: $color-gray;
  background-color: $color-primary;
  pointer-events: all !important;
  border-radius: calculateEm(16);
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  font-size: 1.1rem;
  position: relative;
  width: calculateRem(100);

  @include lg {
    border-radius: calculateEm(16);
    right: 5px;
    top: 5px;
    height: calc(100% - 10px);
    width: 70px;
    position: absolute;
  }

  &:hover:not([disabled]) {
    color: $color-white;
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }

  &.active {
    color: $color-white;

    &:hover:not([disabled]) {
      color: $color-gray;
    }
  }

  &:disabled {
    pointer-events: none;

    &:hover {
      color: unset;
    }
  }
}
