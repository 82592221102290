@import '/src/assets/sass/variables.module.scss';

.home-text13 {
  color: var(--dl-color-scheme-white);
  font-size: 1em;
  font-style: normal;
  font-family: Montserrat;
  font-weight: 600;
  line-height: 1.5;
  margin-right: var(--dl-space-space-halfunit);
}
.home-text14 {
  color: #b4bcc3;
  font-size: 0.9em;
  font-style: normal;
  font-family: Montserrat;
  font-weight: 600;
  line-height: 1.5;
}

.exrond-liquidity-input-selector {
  width: 100%;
  padding-top: 4px;
  border-color: rgba(44, 39, 33, 0.88);
  border-width: 1px;
  padding-left: 15px;
  border-radius: 12px;
  padding-right: 4px;
  padding-bottom: 4px;
  background-color: white;

  &.primary {
    background-color: $color-primary;
  }
  &.secondary {
    background-color: $color-primary-dark;
  }

  &.exrond-select {
    width: 100%;
    padding-left: 0px;
    border-radius: 1.875rem 0.45rem 0.45rem 1.875rem;
    .exrond__control {
      caret-color: transparent;
      border-radius: 1.875rem 0.45rem 0.45rem 1.875rem;
      .exrond__indicators {
        .exrond__indicator {
          background-color: transparent;
          &.exrond__dropdown-indicator {
            padding: 8px 4px 0px 0px;
          }
        }
      }
    }
  }

  .exrond-liquidity-input-selector-content {
    width: 100%;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }

  input[type='number'],
  input[type='text'] {
    -moz-appearance: textfield; /* Firefox */
    outline: none;
    border: none;
  }
}
