.container.register {
  max-width: 100%;

  @include sm {
    max-width: 80%;
  }

  @include md {
    max-width: calculateRem(650);
  }
}

.container.choose-pair {
  width: 100%;

  @include md {
    width: auto;
    min-width: calculateRem(789);
  }
}

.card.register-card {
  border-radius: calculateEm(40);
  padding-right: calculateEm(20);
  padding-left: calculateEm(20);
  padding-top: calculateEm(30);
  padding-bottom: calculateEm(30);
  background-color: $color-primary;

  .register-title {
    font-size: calculateRem(20);
    font-weight: 500;
    color: $color-light-gray;
  }

  .register-heading {
    font-size: calculateRem(16);
    font-weight: 500;
    color: $color-white;
  }

  .register-continue-button {
    @extend .exrond-btn;
    min-width: calculateEm(114);
    min-height: calculateEm(42);
    border-radius: calculateEm(8) !important;
    background-color: $color-primary-light !important;
    color: $color-white;
    font-size: calculateRem(12);
    font-weight: 500;
    line-height: 1.5;
    font-family: Montserrat;
    &.inactive,
    :disabled {
      background-color: $color-primary-dark !important;
      color: $color-gray !important;
      opacity: 1;
    }
  }
}

.register-dummy-contract-container {
  gap: calculateEm(20);
  margin-top: calculateEm(50);
}

.register-dummy-contract {
  min-width: calculateEm(300);
  border-radius: calculateEm(8);
  background-color: $color-primary-dark;
  color: #fff;
  z-index: 1;
  padding: 0;
  min-height: calculateEm(100);

  &.active {
    border: 1px solid $color-primary-light;
  }

  @include md {
    min-width: calculateEm(350);
  }

  .mark {
    background-color: $color-gray;
    height: 40%;
    border-top-right-radius: calculateEm(6);
    border-bottom-right-radius: calculateEm(6);

    &.active {
      background-color: $color-primary-light;
    }
  }

  .token-icon.stake-token-reward-token-icon {
    margin-left: calculateEm(-15);
    width: 40px;
    height: 40px;
  }

  .tvl-label {
    font-size: calculateRem(10);
  }

  .tvl-text-container {
    position: initial;
    bottom: 0px;
    transform: translate(0px, calculateEm(12));
  }

  .nft-token-farming-token-icon {
    width: calculateEm(58);
    height: calculateEm(58);
    border-radius: calculateEm(20);
  }

  .stake-token-farming-token-icon {
    width: calculateEm(58);
    height: calculateEm(58);
  }

  .svg-lock {
    width: calculateEm(7);
    height: calculateEm(9);
    margin-right: calculateEm(2) !important;
    margin-left: calculateEm(2) !important;

    &.disabled {
      fill: $color-gray;
    }
  }
}
