@import '/src/assets/sass/css-breakpoint-mixins.scss';
@import '/src/assets/sass/variables.module.scss';

.mark--stripes {
  background: repeating-linear-gradient(
    45deg,
    $color-warning,
    $color-warning calculateRem(4),
    $color-primary calculateRem(4),
    $color-primary calculateRem(7)
  );
}

.mark--red {
  background-color: $color-error;
}

.mark--light-blue {
  background-color: $color-primary-light;
}
