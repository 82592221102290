@import '/src/assets/sass/css-breakpoint-mixins.scss';
@import '/src/assets/sass/variables.module.scss';
@import '/src/assets/sass/exrond.scss';

.tokenomics {
  &-container {
    width: 100%;
    height: 100%;
    overflow: auto;
    background: radial-gradient(
      175.11% 167.58% at 9.62% -51.69%,
      #6beafd 0%,
      #004de2 86.86%,
      #001aff 96.98%
    );

    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: calculateRem(15) 0;
    padding-top: calculateRem(130);
    margin-top: calculateRem(-114);
  }
  &-title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: calculateRem(28);
    line-height: calculateRem(34);
    color: $color-white;
    display: flex;
    align-items: center;

    @include sm-max {
      font-size: calculateRem(20);
      line-height: calculateRem(24);
    }
  }
  &-subtitle {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: calculateRem(16);
    line-height: calculateRem(18);
    color: $color-white;
    display: flex;
    align-items: center;

    @include sm-max {
      font-size: calculateRem(12);
    }
  }
  &-text {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: calculateRem(14);
    line-height: calculateRem(18);
    color: $color-white;
    &__font-weight-600 {
      font-weight: 600;
    }

    @include sm-max {
      font-size: calculateRem(10);
      line-height: calculateRem(18);
    }
  }
  &__flower {
    max-width: 100%;
    width: 100%;
    margin-left: 0;

    @include lg {
      margin-left: -23px;
      max-width: 65%;
      width: auto;
    }

    &__title {
      @extend .tokenomics-text;
      font-size: 14px;
      font-weight: 600;

      text-align: center;

      @include lg {
        text-align: start;
        font-size: 18px;
      }
    }

    &--rotated {
      transform: rotate(60deg);
    }
  }
  &-item {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
  }
  &__progress {
    &-wrapper {
      width: 100%;
      position: relative;
    }
    &-details {
      position: relative;
    }
    &-detail {
      position: absolute;
      bottom: 5%;
      display: flex;
      flex-direction: column;
      text-wrap: nowrap;

      &-bottom {
        top: 5%;
        .percentage {
          order: 2;
        }
        .text {
          order: 3;
        }
        .line {
          order: 1;
        }
      }

      &-ghost {
        position: relative;
        visibility: hidden;
        width: 0;
      }
    }
    &-bar {
      position: relative;
      height: calculateRem(80);
      background: linear-gradient(270deg, #000fc9 0%, #022dff 100%);
      border-radius: 10px;

      &-line {
        position: absolute;
        width: 2px;
        background-color: rgba(0, 0, 0, 0.125);
        height: 100%;
      }
    }
  }
}
.percentage {
  order: 2;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  color: $color-white;
  font-size: calculateRem(14);
  line-height: calculateRem(21);
  @include sm-max {
    order: 1;
    font-size: calculateRem(12);
  }
}
.text {
  order: 1;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  color: $color-white;
  font-size: calculateRem(10);
  line-height: calculateRem(12);
  @include sm-max {
    order: 2;
    font-size: calculateRem(8);
    line-height: calculateRem(10);
    max-width: 88px;
    white-space: pre-wrap;
  }
}
.line {
  order: 3;
  width: 2px;
  height: calculateRem(15);
  background-color: $color-white;
}

.bottom-buttons {
  width: 100%;
  height: fit-content;
  margin-top: calculateRem(10);
  position: sticky;
  z-index: 1;
  bottom: 0;
  align-items: center;
  padding-bottom: calculateRem(10);

  &__back {
    @extend .exrond-text;
    font-size: calculateEm(12);
    font-weight: 500;
    height: calculateRem(42);
    width: 50%;
    background-color: $color-primary;
    border-radius: calculateRem(8) 0 0 calculateRem(8);
    margin: 0;
    border: none;
    color: $color-white;
    position: relative;
  }

  &__back-icon {
    position: absolute;
    top: 50%;
    left: 10%;
    transform: translate(-50%, -50%);
  }

  &__mint {
    @extend .exrond-text;
    font-size: calculateEm(12);
    font-weight: 500;
    width: 50%;
    height: calculateRem(42);
    background-color: #6beafd;
    border-radius: 0 calculateRem(8) calculateRem(8) 0;
    margin: 0;
    border: none;
    color: $color-black;
  }
}

.burnrate {
  &__text {
    @extend .tokenomics-text;
    font-weight: 400;
    font-size: 10px;
    line-height: 1;

    &--bold {
      font-weight: 600;
      font-size: 10px;
    }
  }

  &__img {
    background-image: url('/assets/img/blue-chips/circulating_supply_mobile.png');
    background-repeat: no-repeat;
    margin-left: -10px;
    height: 133px;
    width: 332px;

    @include md {
      height: 203px;
      width: 501px;
      margin-left: 0;
      background-image: url('/assets/img/blue-chips/circulating_supply.png');
    }
  }
}
