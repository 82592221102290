@import '/src/assets/sass/variables.module.scss';
@import '/src/assets/sass/css-breakpoint-mixins.scss';
@import '/src/assets/sass/exrond.scss';

.blue-chips-header {
  background: radial-gradient(
    197.87% 197.87% at 9.62% -51.69%,
    #6beafd 0%,
    #004de2 64.73%,
    #001aff 96.98%
  );
  height: calculateRem(288);
  width: 100%;
  position: relative;
  overflow: hidden;
  font-family: Montserrat;
  border-radius: 20px;
  z-index: 1;

  &__mobile {
    height: calculateRem(243);
    // width: 100vh;

    &__mini {
      height: calculateRem(114);
    }
  }

  &__right-top-star {
    background-image: url('../../assets/img/blueChipsHeaderTopRightStar.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    position: absolute;
    width: calculateRem(70);
    height: calculateRem(70);
    right: calculateRem(16);
    top: calculateRem(31);

    &__mobile {
      background-size: cover;
      width: calculateRem(40);
      height: calculateRem(30);
      right: calculateRem(10);
      top: calculateRem(15);

      &__mini {
        width: calculateRem(33);
        height: calculateRem(33);
        right: calculateRem(-4);
        top: calculateRem(-8);
      }
    }
  }

  &__right-down-crystals {
    background-image: url('../../assets/img/blueChipsHeaderDownRightCrystals.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    position: absolute;
    width: calculateRem(77);
    height: calculateRem(115);
    right: calculateRem(0);
    top: calculateRem(173);

    &__mobile {
      background-image: url('../../assets/img/blueChipsHeaderDownRightCrystalsMobile.svg');
      background-size: contain;
      width: calculateRem(68);
      height: calculateRem(83);
      right: calculateRem(-5);
      top: calculateRem(159);

      &__mini {
        width: calculateRem(38);
        height: calculateRem(47);
        right: calculateRem(-4);
        top: calculateRem(70);
      }
    }
  }

  &__center-stars {
    background-image: url('../../assets/img/blueChipsHeaderCenterStars.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    position: absolute;
    width: calculateRem(130);
    height: calculateRem(90);
    left: calculateRem(274);
    top: calculateRem(198);

    &__mobile {
      background-size: cover;
      background-image: url('../../assets/img/blueChipsHeaderCenterStarsMobile.svg');
      width: calculateRem(45);
      height: calculateRem(42);
      left: calculateRem(114);
      top: calculateRem(139);
    }
  }

  &__left-tokens {
    background-image: url('../../assets/img/blueChipsHeaderLeftTokens.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    position: absolute;
    width: calculateRem(247);
    height: calculateRem(174);
    left: calculateRem(78);
    top: calculateRem(63);

    &__mobile {
      background-image: url('../../assets/img/blueChipsHeaderLeftTokensMobile.svg');
      background-size: contain;
      width: calculateRem(181);
      height: calculateRem(127);
      left: calculateRem(-10);
      top: calculateRem(39);
    }
  }

  &__left-top-star {
    background-image: url('../../assets/img/blueChipsHeaderLeftTopStar.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    position: absolute;
    width: calculateRem(69);
    height: calculateRem(81);
    left: calculateRem(0);
    top: calculateRem(18);

    &__mobile {
      background-image: url('../../assets/img/blueChipsHeaderLeftTopStarMobile.svg');
      background-size: contain;
      width: calculateRem(50);
      height: calculateRem(48);
      left: calculateRem(10);
      top: calculateRem(28);

      &__mini {
        width: calculateRem(33);
        height: calculateRem(33);
        left: calculateRem(-6);
        top: calculateRem(-1);
      }
    }
  }

  &__left-down-crystals {
    background-image: url('../../assets/img/blueChipsHeaderLeftDownCrystals.svg');
    background-repeat: no-repeat;
    background-size: initial;
    background-position: center;
    position: absolute;
    width: calculateRem(108);
    height: calculateRem(123);
    left: calculateRem(0);
    top: calculateRem(175);

    &__mobile {
      background-image: url('../../assets/img/blueChipsHeaderLeftDownCrystalsMobile.svg');
      background-size: contain;
      width: calculateRem(67);
      height: calculateRem(83);
      left: calculateRem(-7);
      top: calculateRem(167);

      &__mini {
        width: calculateRem(43);
        height: calculateRem(53);
        left: calculateRem(-4);
        top: calculateRem(70);
      }
    }
  }

  &__text-medium-1 {
    font-size: 11px;
    font-weight: 600;
    line-height: calculateRem(18);
    position: absolute;
    right: 619px;
    top: 90px;
  }

  &__text-medium-2 {
    font-size: 11px;
    font-weight: 600;
    line-height: calculateRem(18);
    position: absolute;
    right: 480px;
    top: 151px;
  }

  &__text-big {
    font-size: 48px;
    font-weight: 700;
    line-height: calculateRem(58);
    position: absolute;
    right: 418px;
    top: 99px;
  }

  &__mint-btn-container {
    display: flex;
    justify-content: end;
    margin-top: calculateRem(85);

    @include lg {
      margin-right: calculateRem(63);
      margin-top: calculateRem(90);
    }

    &__mobile {
      &__mini {
        margin-top: calculateRem(0);
      }
    }
  }

  &__bottom-buttons {
    background: linear-gradient(270deg, #0029ff 0%, #3a7dff 100%);
    font-size: 12px;
    font-weight: 500;
    line-height: calculateRem(14);
    position: absolute;
    width: calculateRem(238);
    height: calculateRem(32);
    right: 339px;
    top: 238px;
    border: 1px solid #6beafd;
    border-radius: 10px;
    color: white;

    &--clicked {
      background: #141e2f;
    }

    &__mobile {
      background: linear-gradient(270deg, #0029ff 0%, #3a7dff 100%);
      width: calculateRem(150);
      height: calculateRem(32);
      right: calculateRem(180);
      top: calculateRem(196);
      font-size: 10px;

      &--clicked {
        background: #141e2f;
      }

      &__mini {
        position: initial;
        width: 80%;
        z-index: 1;
      }
    }

    &__vesting {
      right: 82px;

      &__mobile {
        right: calculateRem(20);
      }
    }

    &__mint {
      background: #6beafd;
      font-size: 14px;
      line-height: calculateRem(17);
      position: initial;
      color: black;
      height: calculateRem(32);

      &__mobile {
        font-size: 10px;
        line-height: calculateRem(12);

        &__mini {
          width: calculateRem(310);
          height: calculateRem(32);
        }
      }
    }

    &__connect {
      background-color: #6beafd !important;
      font-size: 14px;
      line-height: calculateRem(17);
      position: initial;
      width: 17rem;
      color: black;
      border-radius: 10px !important;

      svg {
        fill: black;
      }

      &:hover {
        color: black;
      }

      &__mobile {
        width: 10rem;
      }

      &__mini {
        width: calc(100% - 40px);
      }
    }
  }
}

.exr-page {
  &__sub-titles {
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 24px;
    line-height: 18px;
    color: #ffffff;
    margin-top: 70px;
    margin-bottom: 0 !important;
  }
}
