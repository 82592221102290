@import '/src/assets/sass/variables.module.scss';
@import '/src/assets/sass/css-breakpoint-mixins.scss';
@import '/src/assets/sass/exrond.scss';

.liquid-vesting {
  background: radial-gradient(
    137.65% 138.25% at 12% 7.59%,
    #6beafd 0%,
    #004de2 68.41%,
    #001aff 96.98%
  );
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: calculateRem(8);
  padding: 0;
  border: none;
  display: flex;
  justify-content: end;
  cursor: pointer;

  @include lg {
    border-radius: calculateRem(20);
  }

  &__empty {
    height: calculateRem(130);

    @include lg {
      height: calculateRem(287);
    }
  }

  &__content {
    min-height: calculateRem(155);
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    width: 100%;

    @include lg {
      align-items: center;
    }
  }

  &__bluechips {
    width: calculateRem(58);
    height: calculateRem(58);
    margin-left: calculateRem(10);

    @include lg {
      width: calculateRem(104);
      height: calculateRem(104);
      margin-left: calculateRem(22);
    }
  }

  &__text-container {
    display: flex;
    flex-direction: column;
    margin-left: calculateRem(10);

    @include lg {
      margin-left: calculateRem(22);
    }
  }

  &__title {
    @extend .exrond-text;
    font-weight: 500;
    font-size: calculateRem(20);
    color: $color-white;
    line-height: 1;

    @include lg {
      font-size: calculateRem(28);
    }
  }

  &__subtitle {
    @extend .exrond-text;
    font-weight: 500;
    font-size: calculateRem(8);
    color: #6beafd;

    @include lg {
      font-size: calculateRem(10);
    }
  }

  &__verified {
    width: calculateRem(10);
    height: calculateRem(10);
    border: 1px solid #6beafd;
    border-radius: 50%;
    margin-left: calculateRem(4);
    flex-shrink: 0;

    @include lg {
      width: calculateRem(13);
      height: calculateRem(13);
    }
  }

  &__chevron {
    transition: transform 0.3s ease-in-out;
    animation: rotateAnimationReverse 0.3s forwards;
    display: none;

    @include lg {
      display: flex;
    }

    &--expanded {
      animation: rotateAnimation 0.3s forwards;
    }
  }

  @keyframes rotateAnimation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(180deg);
    }
  }

  @keyframes rotateAnimationReverse {
    0% {
      transform: rotate(180deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }

  &__mark {
    border-top-right-radius: calculateRem(6);
    border-bottom-right-radius: calculateRem(6);
    width: calculateRem(6);
    height: calculateRem(50);
    background: #6beafd;
    border-radius: 0px calculateRem(6) calculateRem(6) 0px;
    flex-shrink: 0;
    margin-top: calculateRem(4);

    @include lg {
      height: calculateRem(63);
      width: calculateRem(8);
      margin-top: calculateRem(0);
    }
  }

  &__claim-container {
    margin-left: calculateRem(4);
    margin-right: calculateRem(8);
    display: flex;
    flex-direction: row;
    align-items: center;

    @include lg {
      margin-left: calculateRem(15);
      margin-right: calculateRem(20);
    }
  }

  &__claimable {
    border: 1px solid #6beafd;
    border-radius: calculateRem(8);
  }

  &__button {
    color: $color-primary;
    height: calculateRem(40);

    @include lg {
      height: calculateRem(42);
    }
  }

  &__liquid-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-grow: 1;
    margin-right: calculateRem(0);
    margin-top: calculateRem(20);
    position: relative;

    @include lg {
      margin-top: calculateRem(0);
      justify-content: space-evenly;
      margin-right: calculateRem(20);
    }
  }

  &__dots {
    max-width: calculateRem(80);

    @include lg {
      max-width: calculateRem(80);
    }
  }

  &__liquid {
    margin-bottom: calculateRem(8);
  }

  &__connecting-line {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: calculateRem(32);
    min-width: calculateRem(80);
    height: calculateRem(37);
    margin-left: calculateRem(4);
    margin-right: calculateRem(4);
    width: 100%;

    @include lg {
      max-width: calculateRem(80);
    }
  }

  &__dotted-line {
    flex: 1;
    background-image: repeating-linear-gradient(
      to right,
      #6beafd,
      #6beafd 8px,
      transparent 8px,
      transparent 16px
    );
    width: 100%;
  }

  &__vest-btn-container {
    display: none;

    @include lg {
      display: flex;
    }
  }

  &__total-claim {
    background: $color-primary;
    border: 1px solid #6beafd;
    border-radius: calculateRem(12);
    min-height: calculateRem(60);
    padding: calculateRem(4) calculateRem(8) calculateRem(4) calculateRem(8);
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: calculateRem(110);

    @include lg {
      padding: calculateRem(6) calculateRem(14) calculateRem(6) calculateRem(14);
      max-width: calculateRem(168);
    }

    &__exr {
      @extend .exrond-text;
      font-weight: 500;
      font-size: calculateRem(14);
      color: $color-primary-light;
      line-height: 1;

      @include lg {
        font-size: calculateRem(20);
        color: $color-white;
      }
    }

    &__egld {
      @extend .exrond-text;
      font-weight: 500;
      font-size: calculateRem(10);
      color: #6beafd;

      @include lg {
        font-size: calculateRem(10);
      }
    }
  }
}
