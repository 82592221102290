@import '/src/assets/sass/variables.module.scss';

.exrond-swap-card {
  flex: 0 0 auto;
  width: 40%;
  display: flex;
  box-shadow: 0px 20px 30px 10px rgba(20, 30, 47, 0.3);
  align-items: flex-start;
  padding-top: 32px;
  padding-left: 32px;
  border-radius: 40px;
  padding-right: 32px;
  flex-direction: column;
  padding-bottom: 32px;
  .swap-title {
    color: #b4bcc3;
    font-size: 1.3em;
    font-style: normal;
    font-family: Montserrat;
    font-weight: 600;
    margin-bottom: 32px;
  }

  .exrond-input {
    width: 100%;
    padding-top: 4px;
    border-color: rgba(44, 39, 33, 0.88);
    border-width: 1px;
    padding-left: 15px;
    border-radius: 12px;
    margin-bottom: 8px;
    padding-right: 15px;
    padding-bottom: 4px;
    background-color: white;

    &.primary {
      background-color: $color-primary;
    }
    &.secondary {
      background-color: $color-primary-dark;
    }

    .exrond-input-content {
      width: 100%;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      /* display: none; <- Crashes Chrome on hover */
      -webkit-appearance: none;
      margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
    }

    input[type='number'],
    input[type='text'] {
      -moz-appearance: textfield; /* Firefox */
      outline: none;
      border: none;
    }

    .exrond-input-light {
      @extend .exrond-input;
      background-color: rgb(39, 52, 74);
    }
  }
  .percentage-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 16px;
    padding-top: 6px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 6px;
    border-radius: 30px;
    background-color: #394c60;
  }
}
