@import '/src/assets/sass/variables.module.scss';

.my-liquidity-pools-wrapper {
  width: 100%;
  padding: 1rem;
  border-radius: 0.75rem;
  background-color: $color-primary-dark;
  max-height: 25rem;
  min-height: 20rem;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #555;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #f1f1f1;
    &.hover {
      background: #f1f1f1;
    }
  }
}
.no-liquidity-positions {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 20rem;
  span {
    color: white;
    font-size: medium;
    font-weight: 600;
  }
}
