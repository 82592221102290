.card.swap-form {
  box-shadow: 0px 20px 30px 10px rgb(20 30 47 / 30%);
  border-radius: 40px;
  padding: 32px;
  background-color: rgb(39, 52, 74);
  color: #fff;
  z-index: 1;

  &.stake-footer {
    background-color: rgb(20, 30, 47);
    margin-top: -60px;
    padding-top: 92px;
    z-index: 0;
    padding-right: 56px;

    .card-body {
      padding: 0;
    }
  }
}

.arrow-row,
.arrow-column {
  svg {
    fill: #808080;
  }
}

.btn.btn-stake-action,
.btn-stake-position-action {
  cursor: pointer;
  flex: 0 0 auto;
  color: #043119;
  min-width: 6.8rem;
  display: flex;
  font-size: 12px;
  // box-shadow: 0px 20px 30px 10px rgb(20 30 47 / 50%);
  font-style: normal;
  transition: 0.3s;
  align-items: center;
  font-family: Montserrat;
  font-weight: 700;
  border-radius: 15px;
  flex-direction: column;
  padding: 10px;
  justify-content: center;
  background-image: linear-gradient(
    90deg,
    rgb(19, 234, 117) 2%,
    rgb(19, 193, 100) 100%
  );
  margin: 0 4px;
  &:hover {
    color: white;
    background-image: linear-gradient(
      90deg,
      rgb(19, 193, 100) 2%,
      rgb(19, 234, 117) 100%
    );
    text-decoration: none;
  }

  &.exrond-connect-btn {
    width: 120px;
    background-color: $color-primary-light;
    color: white;
    background-image: none;
    font-weight: 400;
    flex-direction: row;
    font-size: 12px;
    padding: 10px;
  }
}

.swap-form-group {
  padding: 0.5rem 0.25rem 0.5rem 0rem;
  margin-bottom: 0;
  border: 0;
  border-radius: 3rem;
  background-color: $color-primary;
  -webkit-box-shadow: 0px 0px 5px 0px$color-primary;
  -moz-box-shadow: 0px 0px 5px 0px$color-primary;
  box-shadow: 0px 0px 5px 0px$color-primary;
}

.swap-form-group-light {
  @extend .swap-form-group;
  background-color: $secondary;
}

.swap-form-field {
  .input-group {
    padding: 10px 20px;
  }

  .input-amount {
    font-size: 16px;
    padding: 0%;
    border: 0;
    color: #fff;
    background-color: transparent;
    width: 100%;
    -moz-appearance: textfield;

    &:focus {
      outline: none;
      border-color: inherit;
      -webkit-box-shadow: none;
      box-shadow: none;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      margin: 0;
      -webkit-appearance: none;
    }
  }

  .swap-from-feedback {
    margin-top: 10px;
    padding: 0 25px;
    font-size: 12px;
  }
}

.stake-form-select {
  padding: 5px;
  margin-bottom: 0;
  background-color: $color-primary !important;
  border: 0;
  border-radius: 1.875rem;
  white-space: nowrap;
  -webkit-box-shadow: 0px 0px 5px 0px$color-primary-light;
  -moz-box-shadow: 0px 0px 5px 0px$color-primary-light;
  box-shadow: 0px 0px 5px 0px$color-primary-light;
}

.farm-options-list {
  margin-top: 0px;
  z-index: 1;
  position: absolute;
  right: 0;
  margin-right: 20px;
  background: $color-primary;
  border: 0px;
  -webkit-box-shadow: 0px 0px 5px 0px$color-primary-light;
  -moz-box-shadow: 0px 0px 5px 0px$color-primary-light;
  box-shadow: 0px 0px 5px 0px$color-primary-light;
  border-radius: 1.875rem 1.875rem;
}

.token-select {
  display: block;
}

.collapsing {
  -webkit-transition: none;
  transition: none;
  display: none;
}

.list-group-item-bordered {
  -webkit-box-shadow: 0px 0px 5px 0px$color-primary;
  -moz-box-shadow: 0px 0px 5px 0px$color-primary;
  box-shadow: 0px 0px 5px 0px$color-primary;
  background-color: $color-primary;
  border-radius: 3rem !important;
  padding: 1rem;
}

.arrow-column {
  visibility: hidden;
  max-width: 0;
}

.header-item {
  margin-bottom: 0;
  flex: 0 0 18%;

  .first-child {
    min-width: 12.5rem;
  }

  &-small {
    flex: 0 0 14%;
  }
}

.dotted-underline {
  border-bottom: 1px #667183 dashed;
}

.arrow-row {
  visibility: visible;
  max-width: 100%;
}

.stake-position-buttons {
  flex-direction: row;
  justify-content: flex-end;
  min-width: 300px;
}

@media (max-width: 576px) {
  .token-select {
    display: none;
  }

  .stake-form-select {
    font-size: 10px;
  }
}

@media (max-width: 992px) {
  .arrow-row {
    visibility: hidden;
    max-width: 0;
  }

  .arrow-column {
    visibility: visible;
    max-width: 100%;
    margin-left: auto;
  }

  .stake-position-buttons {
    flex-direction: column;
    min-width: 100px;
  }

  .btn-stake-position-action {
    margin-top: 0.5rem;
    margin-right: 0;
    min-width: 100%;
  }
}
