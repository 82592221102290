@import '/src/assets/sass/variables.module.scss';
@import '/src/assets/sass/css-breakpoint-mixins.scss';

.bluechip-staking-image-container {
  background-color: #141e2f80;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 73px;
  height: 73px;
  border-radius: 23px;

  @include lg {
    width: 105px;
    height: 105px;
    border-radius: 32px;
  }

  .bluechip-staking-image {
    width: 51px;
    height: 52px;
    border-radius: 20px;

    @include lg {
      border-radius: 28px;
    }
  }
}
