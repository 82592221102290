@import './css-breakpoint-mixins.scss';
@import './variables.module.scss';

.bg-exrond {
  background: radial-gradient(50% 50% at 50% 50%, #2b394f 0%, #182333 100%);
}

.exrond-logo {
  width: 200px;
  object-fit: contain;
  text-decoration: none;
}

.exrond-logo-mini {
  width: 50px;
  height: 50px;
  object-fit: contain;
  text-decoration: none;
  fill: #3066ff;
}

.wallet-mini-logo {
  width: 20px;
  object-fit: contain;
  margin-right: 8px;
}

.screen-centered {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 50vh;
}

// TEXT
.font-weight-400 {
  font-weight: 400 !important;
}
.font-weight-500 {
  font-weight: 500 !important;
}
.font-weight-600 {
  font-weight: 600 !important;
}
.font-weight-800 {
  font-weight: 800 !important;
}
.font-size-1 {
  font-size: 1rem !important;
}
.font-size-09 {
  font-size: 0.9rem !important;
}
.font-size-08 {
  font-size: 0.8rem !important;
}
.font-size-10p {
  font-size: calculateRem(10) !important;
}
.font-size-12p {
  font-size: calculateRem(12) !important;
}
.font-size-16p {
  font-size: calculateRem(16) !important;
}
.font-size-18p {
  font-size: calculateRem(18) !important;
}

.exrond-text {
  font-style: normal;
  line-height: 1.5;
  font-family: Montserrat;
}
.text-white {
  color: $color-white;
}
.text-gray {
  color: #808080;
}
.text-black {
  color: black;
}
.text-blue-gray {
  color: $color-gray;
}
.text-red {
  color: $color-error;
}
.text-light-blue {
  color: $color-primary-light;
}
.text-dark-blue {
  color: $color-primary-dark;
}
.text-green {
  color: $color-success;
}
.text-light-gray {
  color: $color-light-gray;
}
// TEXT
.exrond-select {
  &-dark {
    background-color: $color-primary;
    border-radius: 12px;
  }

  .exrond__control {
    border-style: none;
    background-color: inherit;

    .exrond__value-container {
      .exrond__placeholder,
      .exrond__input-container,
      .exrond__single-value {
        min-height: 35px;
        display: flex;
        flex-direction: row;
        align-items: center;
        @extend .exrond-text;
        @extend .text-white;
        @extend .font-size-1;
        @extend .font-weight-600;
      }
    }

    .exrond__indicators {
      .exrond__indicator-separator {
        display: none;
      }
      .exrond__indicator {
        min-height: 35px;
      }
    }

    &:hover {
      border-style: none;
    }
  }
  .exrond__menu {
    border-style: none;
    background-color: inherit;
    border-radius: 12px;
    .exrond__menu-list {
      max-height: 200px;
      .exrond__option {
        @extend .exrond-text;
        @extend .text-white;
        @extend .font-size-1;
        @extend .font-weight-600;
        border-radius: inherit;
      }
    }
    /* Scroll Bar */
    .exrond__menu-list::-webkit-scrollbar {
      width: 4px;
      height: 0px;
    }
    .exrond__menu-list::-webkit-scrollbar-track {
      background: #555;
    }
    .exrond__menu-list::-webkit-scrollbar-thumb {
      background: #f1f1f1;
    }
    .exrond__menu-list::-webkit-scrollbar-thumb:hover {
      background: #f1f1f1;
    }
  }
}

.exrond-btn {
  background-color: $color-primary;
  border-radius: 25px;
  border: none;
  padding: 12px 32px;
  &:disabled {
    opacity: 0.5;
  }
}

.exrond-btn-light {
  @extend .exrond-btn;
  background-color: rgb(39, 52, 74);
}

.btn-small {
  padding: 0.25rem 0.5rem 0.25rem 0.5rem;
  font-size: 0.7rem !important;
  line-height: 1.5 !important;
  font-weight: 300;
}

.exrond-btn-light-blue {
  @extend .exrond-btn;
  background-color: $color-primary-light;
}

.exrond-btn.small-corners {
  border-radius: 8px;
  padding: 8px;
}

.exrond-gradient-container {
  background: linear-gradient(180deg, rgba(24, 35, 51, 0) 0%, #182333 27.08%);
}

.cursor-pointer {
  cursor: pointer !important;
}
