@import '/src/assets/sass/variables.module.scss';
@import '/src/assets/sass/css-breakpoint-mixins.scss';
@import '/src/assets/sass/exrond.scss';

.deploy__button {
  min-width: calculateRem(100);
  min-height: calculateRem(51);
  border-radius: calculateEm(12);
  border: 1px solid $color-primary-dark;
  background-color: $color-primary;
  color: $color-white;
  font-size: calculateRem(12);
  font-weight: 500;
  line-height: 1.5;
  font-family: Montserrat;
}

.deploy__button--active {
  background-color: $color-primary-light;
}

.deploy__container {
  width: calculateEm(300);

  @include lg {
    width: calculateEm(350);
  }
}

.deploy__button-close {
  @extend .exrond-btn;
  min-width: calculateEm(47);
  min-height: calculateEm(30);
  background-color: $color-primary-dark;
  border-radius: calculateEm(8);
  padding: 0;
  position: inherit;
  right: 0;

  @include sm {
    position: absolute;
    right: calculateEm(25);
  }
}

.deploy__button-secondary {
  @extend .deploy__button;
  min-height: calculateEm(51);
  border-radius: calculateEm(12);
  border: 1px solid $color-primary-dark;
}

.deploy__button-secondary--active {
  border: 1px solid $color-primary-light;
}

.deploy__button--green {
  background-color: $color-success;
  color: $color-primary;
}

.deploy__button--alert {
  background-color: $color-primary-dark;
  color: $color-error;
}
