.token-icon.stake-token-farming-token-icon {
  width: 58px;
  height: 58px;
  @include lg {
    width: 104px;
    height: 104px;
  }
  border: 7px solid $color-primary-dark;
  z-index: 2;
}

.token-icon.nft-token-farming-token-icon {
  @extend .stake-token-farming-token-icon;
  width: 58px;
  height: 58px;
  border-radius: 20px;
  @include lg {
    width: 104px;
    height: 104px;
    border-radius: 32px;
  }
}

.token-icon.stake-token-reward-token-icon {
  width: 40px;
  height: 40px;
  @include lg {
    width: 70px;
    height: 70px;
    margin-left: -28px;
  }
  margin-left: -15px;
  opacity: 0.5;
}

.token-icon.small-token-staking {
  width: 40px;
  height: 40px;

  @include lg {
    width: 58px;
    height: 58px;
  }

  border: 4px solid $color-primary;
  z-index: 2;
}

.small-nft-icon {
  border-radius: 14px;

  @include lg {
    border-radius: 20px;
  }
}

.token-icon.small-token-type {
  width: 22px;
  height: 22px;
  margin-left: -9px;

  @include lg {
    margin-left: -10px;
    width: 32px;
    height: 32px;
  }

  background-color: $color-primary-dark;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card.card-staking {
  box-shadow: 0px 20px 30px 10px rgb(20 30 47 / 30%);
  border-radius: 8px;
  background-color: $color-primary-dark;
  color: #fff;
  z-index: 1;
  padding: 0;
  min-height: 130px;

  @include lg {
    border-radius: 40px;
    min-height: 144px;
  }

  .mark {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    min-width: 6px;
  }

  .mark.small {
    height: 30%;
    margin-top: 18px;

    @include lg {
      margin-top: 0;
      height: 40%;
    }
  }

  .tvl-text-container {
    position: initial;
    bottom: 0px;
    transform: translate(0px, 14px);

    @include lg {
      position: absolute;
      bottom: 17px;
      transform: translate(100px, 0);
    }
  }

  .tvl-label {
    font-size: 10px;

    @include lg {
      font-size: 16px;
    }
  }

  .staking-farm-title {
    font-size: 16px;

    @include lg {
      font-size: 18px;
    }
  }

  .tvl-progress {
    margin-top: 0px;
    @include lg {
      width: 75% !important;
      margin-left: auto;
      margin-top: 10px !important;
    }
  }

  &.stake-footer {
    background-color: $color-primary;
    margin-top: 6px;
    border: none;
    padding-top: 0px;
    z-index: 0;

    @include lg {
      margin-top: -60px;
      padding-top: 60px;
    }

    .card-body {
      padding: 0;
    }
  }

  .svg-lock {
    width: 10px;
    height: 13px;

    @include lg {
      width: 11px;
      height: 13px;
    }
  }

  .svg-verified {
    width: 12px;
    height: 12px;

    @include lg {
      width: 15px;
      height: 15px;
    }
  }
}

.token-staking-button {
  font-family: Montserrat;
  line-height: 1.5;
  font-weight: 500;
  background: $color-primary-light;
  border-radius: 8px;
  font-size: 12px;
  font-weight: 500;
  padding: 12px 0px 12px 0px;
  min-width: 100px;
  color: $color-white;
  border: none;
  height: 40px;

  @include lg {
    height: 42px;
  }
}

.token-staking-button-hovered {
  font-family: Montserrat;
  line-height: 1.5;
  font-weight: 500;
  border-radius: 8px;
  min-width: 100px;
  height: 40px;
  padding: 2px 0px 2px 0px;
  color: $color-white;
  border: none;

  @include lg {
    height: 42px;
  }

  &.primary {
    background-color: $color-primary;
  }

  &.secondary {
    background-color: $color-primary-dark;
  }

  .small-mark {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    width: 5px;
    height: 60%;
  }

  &.green-bg {
    background-color: $color-success;
    color: $color-black;
  }

  &.yellow-bg {
    background-color: $color-warning;
    color: $color-primary;
  }

  &.blue-bg {
    background-color: $color-primary;
    color: $color-gray;
  }

  &.green-border-bg {
    background-color: $color-primary;
    border: 1px solid $color-success;
    color: $color-success;
  }
}

.token-staking-container {
  > .token-staking-farm {
    border-bottom: 1px solid $color-primary-dark;

    @include lg {
      border-bottom: 1px solid #0000001f;
    }
  }

  > :first-child {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    @include lg {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }

  > :last-child {
    border-bottom: none;
    margin-bottom: 0px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    @include lg {
      margin-bottom: 20px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}

.staking-modal {
  border-radius: 30px;
  padding-left: 12px;
  padding-right: 12px;
}

.staking-form-field.input-error {
  border: 1px solid $color-error;
}

.staking-form-field {
  background-color: $color-primary;
  border-radius: 12px;
  padding: 5px 15px;

  @include lg {
    border-radius: 8px;
  }

  .exrond__option {
    padding: 6px;
    border-radius: 8px;
  }

  .exrond-input {
    border-radius: 8px;

    &.primary {
      background-color: $color-primary;
    }

    &.secondary {
      background-color: $color-primary-dark;
    }
  }

  .exrond__menu-list {
    > .exrond__option {
      border-bottom: 1px solid #0000001f;
    }

    > :last-child {
      border-bottom: none;
    }
  }

  .exrond-select {
    width: 85%;

    .exrond__menu {
      padding: 4px;
      border-radius: 8px;
      padding: 0;
    }
  }

  .input-amount {
    font-family: Montserrat;
    font-size: 10px;
    padding: 0%;
    font-weight: 400;
    border: 0;
    color: $color-white;
    background-color: transparent;
    width: 100%;
    -moz-appearance: textfield;
    appearance: textfield;

    @include lg {
      font-size: 14px;
    }

    &:focus {
      outline: none;
      border-color: inherit;
      -webkit-box-shadow: none;
      box-shadow: none;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      margin: 0;
      -webkit-appearance: none;
    }
  }
}

.stake-slider.slider-error .rc-slider .rc-slider-track {
  background-color: $color-error;
}

.stake-slider.slider-error .rc-slider .rc-slider-handle {
  background-color: $color-error;
}

.stake-slider {
  .slider-left-side {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    width: 20px;
    height: 18px;
    background-color: $color-primary;
    margin-top: 0.5px;
    margin-right: -15px;
  }

  .slider-right-side {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    width: 20px;
    height: 18px;
    background-color: $color-primary;
    margin-top: 0.5px;
    margin-left: -15px;
  }

  .rc-slider {
    position: relative;
    height: 0.875rem;
    padding: 0.3125rem 0;
    width: 100%;
    border-radius: 0.375rem;
    touch-action: none;
    box-sizing: border-box;
    padding-bottom: 1.5rem;
    width: calc(100% - 0.8rem);
    -webkit-tap-highlight-color: rgba(163, 41, 41, 0);
  }

  .rc-slider-rail {
    position: absolute;
    width: 100%;
    background-color: $color-primary;
    height: 18px;
    border-radius: 0px;
    top: 0.5px;
  }

  .rc-slider-track {
    position: absolute;
    left: 0;
    height: 10px;
    border-radius: 6px;
    background-color: $color-primary-light;
  }

  .rc-slider-handle {
    position: absolute;
    width: 22px;
    height: 22px;
    cursor: pointer;
    cursor: -webkit-grab;
    margin-top: -0.4rem;
    cursor: grab;
    border: 6px solid #fff;
    border-radius: 20%;
    background-color: $color-primary-light;
    touch-action: pan-x;

    @include lg {
      width: 14px;
      height: 14px;
      border: 4px solid #fff;
      margin-top: -0.13rem;
    }
  }

  .rc-slider-dot {
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: $color-gray;
    border-radius: 20%;
    cursor: pointer;
    vertical-align: middle;
    top: 0;
  }

  .rc-slider-dot.rc-slider-dot-active {
    border: none !important;
    width: 6px;
    height: 6px;
    border-radius: 20%;
    background-color: $color-white !important;
    top: 2px;
  }

  .rc-slider-step {
    position: absolute;
    width: 100%;
    height: 0.25rem;
    background: transparent;
  }

  .rc-slider-mark {
    position: absolute;
    top: 1.7rem;
    left: 0;
    width: 100%;
    font-size: 12px;
    font-weight: 500;
  }

  .rc-slider-mark-text-active {
    color: $color-white;
  }

  .rc-slider-mark-text {
    position: absolute;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
    color: $color-white;
  }
}

.card.unlock-schedule-card {
  border-radius: 20px;
  background-color: $color-primary;
  padding: 20px 15px;
  border: none;

  .header {
    background-color: $color-primary-dark;
    border-radius: 10px;
    padding: 4px;
    border: none;
  }

  .unlock-line {
    border-radius: 4px;
    background-color: $color-primary-dark;
    height: 10px;
  }

  .unlock-box {
    width: 6px;
    height: 6px;
    background-color: $color-primary;
    border-radius: 2px;
  }
}

.staking-sticky-container {
  position: fixed;
  right: 0;
  z-index: 1;
  bottom: 0;
  height: 90px;
  align-items: center;

  .sticky-button {
    width: 95%;
  }
}

.staking-sticky-container:not(.sticky) {
  background-color: transparent;
}

.card.card-stake-mobile {
  box-shadow: 0px 20px 30px 10px rgb(20 30 47 / 30%);
  border-radius: 20px;
  background-color: $color-primary-dark;
  color: #fff;
  padding: 15px 5px;

  .card-selection {
    min-height: 52px;
    border-radius: 12px;
    border: 1px solid $color-gray;
    background-color: $color-primary;

    &.disabled {
      border-color: $color-black-12alpha;
    }

    &.selected {
      border-color: $color-primary-light;
    }
  }

  .svg-lock {
    width: 10px;
    height: 13px;
  }
}

.stake-token-balance-container {
  flex-wrap: wrap;
  flex-direction: column;
  align-content: end;
  justify-content: center;

  @include lg {
    flex-direction: row;
    align-content: start;
    justify-content: end;
  }

  .balance-title {
    font-size: 10px;

    @include lg {
      font-size: 12px;
    }
  }
}

.clickable {
  cursor: pointer;
}

.stake-selector-header {
  font-size: 12px;

  @include lg {
    font-size: 16px;
  }
}

.nft-staking-image-container {
  width: 73px;
  height: 73px;
  border-radius: 23px;

  @include lg {
    width: 105px;
    height: 105px;
    border-radius: 32px;
  }

  .nft-staking-image {
    border-radius: 20px;

    @include lg {
      border-radius: 28px;
    }
  }
}

.token-label {
  height: 35px;
  min-width: 25%;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;

  &.left {
    left: 0;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  &.right {
    right: 0;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  @include lg {
    height: 42px;
  }
}

.filter-button {
  background-color: $color-primary-light;
  width: 47px;
  border-radius: 8px;
}
