@import 'assets/sass/css-breakpoint-mixins.scss';
@import 'assets/sass/variables.module.scss';
@import 'assets/sass/exrond.scss';

.swap-panel {
  height: calculateRem(199);
  width: calculateRem(350);
  display: flex;
  flex-direction: column;

  &--expanded {
    border-radius: calculateEm(12) !important;
    height: auto;
  }

  @include lg {
    height: calculateRem(348);
    width: calculateRem(432);
    box-shadow: 0px 20px 30px 10px rgba(22, 22, 41, 0.3);
  }

  &--primary-dark {
    background-color: $color-primary-dark;
    border-radius: calculateRem(20) calculateRem(20) 0px 0px;
    flex-direction: column-reverse;

    @include lg {
      flex-direction: column;
      border-radius: calculateRem(40);
    }
  }

  &--primary {
    background-color: $color-primary;
    border-radius: 0px 0px calculateRem(20) calculateRem(20);

    @include lg {
      border-radius: calculateRem(40);
    }
  }

  &__title {
    @extend .exrond-text;
    font-weight: 600;
    font-size: calculateEm(12);
    color: $color-light-gray;

    &--primary-dark {
      margin-bottom: calculateRem(10);
      margin-left: calculateRem(10);
      display: block;
    }

    &--primary {
      margin-right: calculateRem(10);
      margin-top: calculateRem(10);
      display: block;
      text-align: end;
    }

    @include lg {
      font-size: calculateEm(18);
      margin-top: calculateRem(34);
      margin-left: calculateRem(32);
      display: block;
      text-align: start;
    }
  }

  &__select {
    &-token {
      &--expanded {
        margin: calculateRem(30) calculateRem(10) calculateRem(10)
          calculateRem(10);
      }

      &--primary-dark {
        margin: calculateRem(30) calculateRem(10) 0px calculateRem(10);

        @include lg {
          margin: calculateRem(35) calculateRem(32) calculateRem(0)
            calculateRem(32);
        }
      }

      &--primary {
        margin: calculateRem(20) calculateRem(10) calculateRem(22)
          calculateRem(10);

        @include lg {
          margin: calculateRem(35) calculateRem(32) calculateRem(0)
            calculateRem(32);
        }
      }
    }

    &-amount {
      &--primary-dark {
        margin: calculateRem(20) calculateRem(10) calculateRem(22)
          calculateRem(10);

        @include lg {
          margin: calculateRem(35) calculateRem(32) calculateRem(0)
            calculateRem(32);
        }
      }

      &--primary {
        margin: 0px calculateRem(10) calculateRem(30) calculateRem(10);

        @include lg {
          margin: calculateRem(35) calculateRem(32) calculateRem(0)
            calculateRem(32);
        }
      }
    }
  }
}
