$color-primary-light: #3066ff;
$color-primary: #141e2f;
$color-primary-dark: #27344a;
$color-warning: #ffc007;
$color-success: #13ea75;
$color-error: #ed1b66;
$color-white: #fff;
$color-black: #000;
$color-gray: #667183;
$color-light-gray: #b4bcc3;
$color-black-12alpha: #00000020;

:export {
  colorPrimaryLight: $color-primary-light;
  colorPrimary: $color-primary;
  colorPrimaryDark: $color-primary-dark;
  colorWarning: $color-warning;
  colorSuccess: $color-success;
  colorError: $color-error;
  colorWhite: $color-white;
  colorBlack: $color-black;
  colorGray: $color-gray;
  colorLightGray: $color-light-gray;
  colorBlack12Alpha: $color-black-12alpha;
  colorLightGray: $color-light-gray;
}
