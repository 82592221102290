@import '/src/assets/sass/variables.module.scss';

.exrond-connect-btn {
  font-style: normal;
  font-family: Montserrat;
  font-weight: 400;
  padding: 10px 24px;
  background-color: $color-primary-light;
  border-radius: 8px;

  &.active {
    background-color: $color-primary-light;
  }

  svg {
    fill: white;
  }
}
